import React from "react";
import {Card, CardContent} from "@material-ui/core";
import './SimplePage.scss';

type Props = {
    title: string;
}

export const SimplePage: React.FunctionComponent<Props> = ({title, children}) => {
    return (
        <div className={"page-simple"}>
            <Card className={"card"}>
                <span className={"page-title"}>
                    {title}
                </span>
                <hr className="criteria-separator"/>
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </div>
    );
}


export default SimplePage;