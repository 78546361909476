import * as React from "react";
import {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_CITIES} from "../Country/gql";
import styled from "styled-components";
import {Autocomplete, AutocompleteProps, UseAutocompleteProps} from "@material-ui/lab";
import {Locale} from "ias-lib";
import {useField} from "react-form";
import {TextField} from "@material-ui/core";
import removeDiacritics from "shared/src/services/removeDiacritics";
import './CitySelect.scss';
import {useDebounce} from "../../hooks/useDebounce";

type Props = {
    label?: string;
    field: string,
    onChange?: (e: ChangeEvent<{}>, child: ReactNode) => void;
    value?: string,
    error?: string | null,
    className?: string;
    required?: boolean;
    inputStyle?: any;
    countryIso?: string;
    useUnlocode?: boolean;
    disabled?: boolean;
    baseValue?: City;
    isPort?: boolean;
    setSelectedCity?: (value: any) => void;
    selectedCity?: City | null;
    minTextLength?: number;
    variant?: "outlined" | "standard";
    substring?: string | null;
    setSubstring?: (arg: string | null) => void;
    // data: City[];
    // subString: string;
    // setSubstring: (c: string) => void;
}

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    background-color: white;
    color: #0000008A;
  }

  .MuiInputLabel-animated {
    z-index: 999;
  }
` as React.ComponentType<AutocompleteProps<string, false, false, false> & UseAutocompleteProps<string, false, false, false>>;


const CitySelect: React.FunctionComponent<Props> = ({
                                                        isPort,
                                                        minTextLength,
                                                        selectedCity,
                                                        baseValue,
                                                        disabled,
                                                        setSelectedCity,
                                                        label,
                                                        field,
                                                        className,
                                                        required,
                                                        inputStyle,
                                                        countryIso,
                                                        useUnlocode,
                                                        variant,
                                                        substring: controlledSubtring,
                                                        setSubstring: setControlledSubtring
                                                    }) => {
    if (isPort === undefined) isPort = false;

    function validateCity(value: string) {
        if (!value && required) {
            return Locale.trans('error.field_required');
        }
        return false;
    }

    const {
        meta: {error},
        getInputProps,
    } = useField(field, {
        validate: validateCity,
        defaultValue: baseValue ? baseValue.id : "",
    });
    const {value, onChange} = getInputProps();


    const [debounceStateValue, setDebounceStateValue] = useState<string | null>(baseValue ? baseValue.fullName : value)
    const debouncedValue = useDebounce(debounceStateValue, 500);

    const [subString, setSubString] = useState<string | null>(baseValue ? baseValue.fullName : value);
    const {data, error: fetchError, loading} = useQuery(GET_CITIES, {
        variables: {countryIso: countryIso, subString: subString, isPort: isPort},
        skip: minTextLength ? (subString ? subString.length : 0) < minTextLength : undefined,
        fetchPolicy: "cache-and-network"
    });

    useEffect(() => {
        setSubString(debouncedValue);
        setControlledSubtring && setControlledSubtring(debounceStateValue);
    }, [debouncedValue]);

    useEffect(() => {
        setControlledSubtring && setControlledSubtring(debounceStateValue);
    }, [])

    useEffect(() => {
        if (controlledSubtring !== debounceStateValue && controlledSubtring !== undefined) {
            setDebounceStateValue(controlledSubtring);
        }
    }, [controlledSubtring])

    useEffect(() => {
        if (selectedCity === null) {
            setDebounceStateValue("");
            setControlledSubtring && setControlledSubtring("")
        }
    }, [selectedCity]);

    const correctCities: City[] = data ? data.cities : [];

    const getFullName = (value: string) => {
        return correctCities.find((city: City) => city.id === value)?.fullName;
    };

    const getCity = (value: string) => {
        return correctCities.find((city: City) => city.id === value);
    }


    useEffect(() => {
        if (useUnlocode) {
            const fullName = getFullName(value);
            if (fullName) {
                setDebounceStateValue(fullName);
            }
        } else {
            setDebounceStateValue(value);
        }
    }, [value]);

    const handleTest = (e: ChangeEvent<{}>, value: string | null) => {
        const eventDuplicate = {
            ...e,
            target: {
                ...e.target,
                value: value,
            }
        } as unknown;//TODO
        onChange(eventDuplicate as ChangeEvent<Element>);
        if (value === null) {
            setDebounceStateValue("");
            if (setSelectedCity) {
                setSelectedCity(null);
            }

        } else {
            if (setSelectedCity) {
                setSelectedCity(getCity(value));
            }
            if (useUnlocode) {
                const fullName = getFullName(value);
                if (fullName) {
                    setDebounceStateValue(capitalize(fullName));
                }

            } else {
                setDebounceStateValue(capitalize(value));
            }

        }
    };

    function capitalize(arg: string) {
        return arg.charAt(0).toUpperCase() + arg.slice(1);
    }

    const handleInputChange = (event: any) => {
        setDebounceStateValue(capitalize(event.target.value));
    };

    const getValue = (city: City) => {
        return useUnlocode ? city.id : city.fullName;
    };

    const getOptions = () => {
        if (minTextLength) {
            if (debounceStateValue?.length && debounceStateValue?.length >= minTextLength) {
                return correctCities.map(getValue);
            }
            return [];
        }
        return correctCities.map(getValue);
    }

    const getNoOptionText = () => {
        if (minTextLength) {
            if (!debounceStateValue || (debounceStateValue?.length && debounceStateValue?.length < minTextLength)) {
                return Locale.trans("minTextLength");
            }
            return Locale.trans("noResults");
        }
        return Locale.trans("noResults");
    }

    return (
        <StyledAutocomplete
            // classes={{
            //     root: classes.root
            // }}
            id={field}
            options={getOptions()}
            value={selectedCity ? selectedCity.id : (value === "" ? null : value)}
            onChange={handleTest}
            autoHighlight
            loading={loading}
            disabled={disabled}
            noOptionsText={getNoOptionText()}
            getOptionSelected={(option, value) => {
                return option === value;
            }}
            getOptionLabel={(city: any) => {
                if (typeof city === "object") {
                    city = city.fullName;
                }
                const option = correctCities.find(item => getValue(item).toLowerCase() === city.toLowerCase());
                return (option && option.fullName) || "";
            }}
            renderOption={(city: any) => {
                const option = correctCities.find(item => getValue(item).toLowerCase() === city.toLowerCase());
                return (
                    <React.Fragment>
                        {option && option.fullName}
                    </React.Fragment>
                )
            }}
            filterOptions={(options: string[], state: { inputValue: string | null }) => options.filter((city: string) => {
                const option = correctCities.find(item => removeDiacritics(getValue(item).toLowerCase()) === removeDiacritics(city.toLowerCase()));
                return (option)// && option.fullName.toLowerCase().includes((state.inputValue || '').toLowerCase()));
            })}
            renderInput={(params) => (
                <>
                    <TextField
                        {...params}
                        error={!!error}
                        helperText={error}
                        required={required}
                        className={`${className} city-select-style`}
                        label={label || Locale.trans('city')}
                        variant={variant ? variant : "outlined"}
                        onChange={handleInputChange}
                        autoComplete="chrome-off"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                            value: debounceStateValue,
                            ...{
                                // city-select-style rule needs to be set in input props for font-family to be applied to value
                                className: inputStyle + " city-select-style"
                            },
                        }}
                        disabled={disabled}
                    />
                    {fetchError && (<p>Erreur</p>)}
                </>
            )}
        />
    );
};

export default CitySelect;