import { lazy } from 'react';
import useCurrentUser from "shared/src/hooks/useCurrentUser";
import {CLIENT, useIsGranted} from "../../services/useIsGranted";


const Home = lazy(() => import('views/client/Home'));
const Bills = lazy(() => import('views/client/bills/Main'));
const Quotations = lazy(() => import('views/client/quotation/Main'));
const Contacts = lazy(() => import('views/client/contacts'));
const Transports = lazy(() => import('views/client/transport/Main'));
const Support = lazy(() => import('views/support/Main'));

const ErrorCompanyArchived = lazy(() => import('views/error/CompanyArchived'));
const ErrorCompanyBlocked = lazy(() => import('views/error/CompanyBlocked'));
const CompanyPending = lazy(() => import('views/error/CompanyPending'));

type T_ROUTE = {
    exact?: boolean;
    component: any;
    path?: string;
}

export function useClientRoutes(): T_ROUTE[] {
    const {loading, isGranted} = useIsGranted();
    const { currentUser } = useCurrentUser();
    const companyStatus = currentUser?.company?.status;
    if(loading || !isGranted(CLIENT)) {
        return [];
    }

    switch (companyStatus) {
        case 'active':
            return [
                {
                    exact: true,
                    path:"/",
                    component:Home
                },
                {
                    exact: false,
                    path:"/quotations",
                    component:Quotations
                },
                {
                    exact: false,
                    path:"/transport",
                    component:Transports
                },
                {
                    exact: false,
                    path:"/contacts",
                    component:Contacts
                },
                {
                    exact: true,
                    path:"/bills",
                    component:Bills
                },
                {
                    exact: true,
                    path:"/support",
                    component:Support
                },
           ];
        case 'blocked':
            return [
                {
                    component: Bills,
                    exact: true,
                    path: "/bills",
                },
                {
                    component: ErrorCompanyBlocked,
                },
            ];
        case 'pending':
            return [{
                component: CompanyPending,
            }];
        default:
            return [{
                component: ErrorCompanyArchived
            }];
    }
}
export default useClientRoutes;
