import React, {Suspense} from 'react';

import './Layout.scss';
import {LoaderOverlay} from 'components';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import {makeStyles, useTheme} from "@material-ui/core/styles";

import AppBarComponent from "../components/MaterialUi/AppBarComponent";
import {ADMIN, ADMIN_EMPLOYEE, useIsGranted} from "../services/useIsGranted";
import AdminMainPage from "./admin/Main";
import {usePartnerRoutes} from "./partner/usePartnerRoutes";
import {Route, Switch} from "react-router";
import e404 from "shared/src/views/error/404";
import SupportMain from "./support/Main";
import IsGrantedRoute from "../components/IsGrantedRoute";
import Company from "./profile/Company";
import Profile from "./profile/Profile";
import Notifications from "./profile/Notifications";
import Settings from './profile/Settings';
import AccessDenied from './profile/AccessDenied';
import {useClientRoutes} from "./client/useClientRoutes";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: '#efefef',
        minHeight: '100vh',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        brackground: '#101e2c',
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    contentContainer: {
        height: '100vh',
        width: '100%',
    },
    content: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: -drawerWidth,
        borderTop: '3px solid #e5e5e5',
        width: '100%',
        overflow: 'auto',
        height: 'calc(100vh - 64px)',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));


export default function Layout() {
    const {loading, isGranted} = useIsGranted(true);
    const clientRoutes = useClientRoutes();
    const partnerRoutes = usePartnerRoutes();

    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <>
            <LoaderOverlay loading={loading}/>
            <div className={clsx(classes.root, "no-scroll-bars", "no-scroll-bars::-webkit-scrollbar")}>
                <CssBaseline/>
                <AppBarComponent/>
                <main
                    className={clsx(classes.contentContainer)}
                >
                    <div className={classes.drawerHeader}/>
                    <div className={clsx(classes.content)}>
                        <div className={"main-content"}>

                            {(!loading && isGranted(ADMIN)) ? (
                                <AdminMainPage/>
                            ) : (
                                <Suspense
                                    fallback={<LoaderOverlay loading={true}/>}
                                >
                                    <Switch>
                                        <Route path="/support" component={SupportMain}/>
                                        <Route path="/profile/my" component={Profile}/>
                                        <Route path={'/profile/notifications'} component={Notifications}/>
                                        <Route path={'/profile/settings'} component={Settings}/>
                                        <IsGrantedRoute
                                            path="/profile/company"
                                            component={Company}
                                            fallBackComponent={AccessDenied}
                                            isGranted={isGranted(ADMIN_EMPLOYEE)}
                                        />
                                        {clientRoutes.map(r => (
                                            <Route key={r.path} exact={r.exact} path={r.path} component={r.component}/>
                                        ))}
                                        {partnerRoutes.map(r => (
                                            <Route key={r.path} exact={r.exact} path={r.path} component={r.component}/>
                                        ))}
                                        {!loading && (
                                            <Route component={e404}/>
                                        )}
                                    </Switch>
                                </Suspense>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
