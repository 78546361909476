import * as React from 'react';
import {Locale} from "ias-lib";
import {useField} from "react-form";
import {TextField} from "@material-ui/core";
import './EmailField.scss';

type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field?: string;
    required?: boolean;
    autocomplete?: "off" | "chrome-off";
    disabled?: boolean;
}


const EmailField: React.FunctionComponent<FieldsProps> = ({
                                                              disabled,
                                                              autocomplete,
                                                              className,
                                                              inputStyle,
                                                              required,
                                                              field
                                                          }) => {
    field = field || "email";

    const isEmail = (value: string) => {
        const regex = new RegExp("([\\.a-zA-Z0-9_\\-])+@([a-zA-Z0-9_\\-])+(([a-zA-Z0-9_\\-])*\\.([a-zA-Z0-9_\\-])+)+");
        return regex.test(value);
    }

    const {
        meta: {error},
        getInputProps,


    } = useField(field, {
        //validate: validateEmail,
        defaultValue: "",
        validate: (value) => {
            if (!isEmail(value)) {
                return Locale.trans("field.email.error");
            }
            return false;
        },


    });
    return (
        <TextField
            {...getInputProps()}
            className={className + " email-field"}
            error={(!!error)}

            helperText={error}
            id={field}
            label={Locale.trans('email')}
            variant="outlined"
            required={required}
            InputProps={{
                classes: {
                    input: inputStyle + " email-field-input"
                }
            }}
            autoComplete={autocomplete}
            disabled={disabled}
        />
    )
};
export default EmailField;
