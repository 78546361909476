import * as React from 'react';
import {Locale} from "ias-lib";
import {useField} from "react-form";
import {TextField} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field: string;
    type: "full"|"company"|"first"|"last";
    required?: boolean;
    autocomplete?: "off"|"chrome-off";
    disabled?: boolean;
    maxLength?: number;
}

const useStyles = makeStyles({
    label: {
        "& .MuiFormLabel-root": {
            fontFamily: 'Lato, sans-serif',
        },
    }
})
const NameField: React.FunctionComponent<FieldsProps> = ({
                                                             autocomplete,
                                                             className,
                                                             inputStyle,
                                                             type,
                                                             required,
                                                             field,
                                                             disabled,
                                                             maxLength
                                                         }) => {
    const classes = useStyles();
    const {
        meta: {error},
        getInputProps
    } = useField(field, {
        defaultValue: '',
    });
    return (
        <TextField
            {...getInputProps()}
            className={clsx(className, classes.label)}
            error={(!!error)}
            helperText={error}
            id={field}
            label={Locale.trans(`name.${type}`)}
            variant="outlined"
            required={required}
            InputProps={{
                className: inputStyle,
                style: {fontFamily: "Lato, sans-serif"},
            }}
            inputProps={{
                maxLength
            }}
            disabled={disabled}
            autoComplete={autocomplete}
        />
    )
};
export default NameField;
