import * as React from 'react';
import {useEffect, useState} from 'react';
import {useField} from "react-form";
import NumberInput from "./NumberInput";
import "./NumberField.scss";
import {useDebounce} from "../../hooks/useDebounce";

type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    validate?: (val: any) => string | false | undefined;
    multiline?: boolean
    rows?: number
    onChange?: (e: any) => void;
    value?: string;
    maxLength?: number;
    startingValue?: number;
    decimal?: boolean;
    negative?: boolean;
    InputProps?: any;
    align?: string;
    onBlur?: any;
    nonZero?: boolean;
    decimalLimit?: number;
    variant?: "standard" | "outlined";
    endAdornment?: any;
    min?: number;
    max?: number;
    useDebouncedValue?: boolean;
}

export const NumberField: React.FunctionComponent<FieldsProps> = ({
                                                                      onBlur,
                                                                      align,
                                                                      disabled,
                                                                      negative,
                                                                      value,
                                                                      decimal,
                                                                      InputProps,
                                                                      startingValue,
                                                                      maxLength,
                                                                      onChange,
                                                                      className,
                                                                      inputStyle,
                                                                      required,
                                                                      field,
                                                                      decimalLimit,
                                                                      validate,
                                                                      label,
                                                                      multiline,
                                                                      rows,
                                                                      nonZero,
                                                                      variant,
                                                                      endAdornment,
                                                                      min,
                                                                      max,
                                                                      useDebouncedValue
                                                                  }) => {
    const {
        meta: {error},
        setValue,
        getInputProps,
        value: fieldValue,
    } = useField(field, {
        validate: validate,
        defaultValue: startingValue || '',
    });


    const [debounceStateValue, setDebounceStateValue] = useState<string>(fieldValue)
    const debouncedValue = useDebounce(debounceStateValue, 500);


    useEffect(() => {
        setValue(debouncedValue)
    }, [debouncedValue]);


    useEffect(() => {
        if (value !== undefined && fieldValue !== value) {
            useDebouncedValue ? setDebounceStateValue(value) : setValue(value);
        }
        // eslint-disable-next-line
    }, [value]);

    const handleChange = (e: any) => {
        if (!decimal && e.target.value) {
            e.target.value = String(parseInt(e.target.value));
        }
        if (!!decimalLimit && e.target.value?.split(".").length === decimalLimit && e.target.value?.split(".")[1].length > decimalLimit) {
            return;
        }
        if (onChange !== undefined) {
            onChange(e);
        }

        useDebouncedValue ? setDebounceStateValue(e.target.value) : setValue(e.target.value);
    };

    return (
        <NumberInput
            {...getInputProps()}
            className={className + " number-field"}
            error={(error)}
            field={field}
            label={label}
            disabled={disabled}
            required={required}
            onBlur={onBlur}
            InputProps={{
                InputProps,
                endAdornment,
                className: inputStyle
            }}
            multiline={multiline}
            rows={rows}
            align={align}
            negative={negative}
            value={useDebouncedValue ? debounceStateValue : fieldValue}
            decimal={decimal}
            maxLength={maxLength}
            inputStyle={inputStyle}
            onChange={handleChange || undefined}
            nonZero={true}
            variant={variant ? variant : "outlined"}
            min={min}
            max={max}
        />
    );
}
export default NumberField;
