import * as React from 'react';
import {Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Locale} from "ias-lib";
import clsx from "clsx";
import {ReactNode} from "react";

type Props = {
    open: boolean;
    title?: string;
    content?: string | ReactNode;
    onAccept?: () => void;
    onRefuse?: () => void;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '15pt',
        },
        text: {
            fontFamily: 'Lato, sans-serif',
        },
        confirm: {
            fontWeight: 'bold',
            textTransform: 'none'
        },
        cancel: {
            textTransform: 'none'
        },
    }));

const Confirm: React.FunctionComponent<Props> = ({open, title, content, onAccept, onRefuse, onClose}) => {

    const classes = useStyles();

    const handleAccept = () => {
        onAccept && onAccept();
    };

    const handleRefuse = () => {
        onRefuse && onRefuse();
    };

    const handleClose = () => {
        onClose && onClose()
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClick={(e) => e.stopPropagation()}
        >
            {title && (<DialogTitle
                        id="alert-dialog-title">
                        <Typography className={clsx(
                            classes.text, classes.title
                        )}>
                            {title}
                        </Typography>
                    </DialogTitle>)}
            {content && (<DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography className={clsx(
                            classes.text
                        )}>
                        {content}
                    </Typography>
                </DialogContentText>
            </DialogContent>)}
            <DialogActions>
                <Button onClick={handleRefuse} color="primary" className={clsx(classes.text, classes.cancel)} disableRipple>
                    {Locale.trans('cancel')}
                </Button>
                <Button onClick={handleAccept} color="primary" autoFocus className={clsx(classes.text, classes.confirm)} disableRipple>
                    {Locale.trans('confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default Confirm;
