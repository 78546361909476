import * as React from "react";

const useConfirm = (title?: string) => {
    const [isOpen, setOpen] = React.useState(false);
    const [content, setContent] = React.useState<string>();
    const [titleInternal, setTitleInternal] = React.useState(title);
    const [idSaved, setIdSaved] = React.useState<string | number>();

    const open = (id: string | number, content?: string, title?: string) => {
        setOpen(true);
        content && setContent(content);
        title && setTitleInternal(title);
        setIdSaved(id);
    };

    const close = () => {
        setOpen(false);
        setContent(undefined);
        setIdSaved(undefined);
    };

    return {
        isOpen,
        title: titleInternal,
        content,
        open,
        close,
        idSaved,
    };
};
export default useConfirm;