import * as React from 'react';
import {Locale} from "ias-lib";
import {useField} from "react-form";
import {TextField} from "@material-ui/core";
import './PhoneField.scss';

type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field?: string;
    required?: boolean;
    autocomplete?: "off"|"chrome-off";
    disabled?: boolean;
}

// function validatePhone(value: string) {
//     if (!value) {
//         return Locale.trans('error.field_required');
//     }
//     //TODO
//     return false;
// }
const PhoneField: React.FunctionComponent<FieldsProps> = ({
                                                              disabled,
                                                              autocomplete,
                                                              className,
                                                              inputStyle,
                                                              required,
                                                              field
                                                          }) => {
    field = field || "phone";
    const {
        meta: {error},
        getInputProps
    } = useField(field, {
        //validate: validatePhone,
        defaultValue:"",
    });
    return (
        <TextField
            {...getInputProps()}
            className={className + " phone-field"}
            error={(!!error)}
            helperText={error}
            id={field}
            label={Locale.trans('phone')}
            variant="outlined"
            required={required}
            InputProps={{
                classes: {
                    input : inputStyle + " phone-field-input"
                }
            }}
            autoComplete={autocomplete}
            disabled={disabled}
        />
    )
};
export default PhoneField;
