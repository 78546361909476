import * as React from 'react';
import ActionButton from "../../../components/MaterialUi/ActionButton";
import clsx from "clsx";
import {Locale} from "ias-lib";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import { useForm} from "react-form";
import Parameters from "../../../parameters";
import PhoneField from "../../../components/Fields/PhoneField";
import EmailField from "../../../components/Fields/EmailField";
import NameField from "../../../components/Fields/NameField";
import {LoaderOverlay} from "../../../components";
import AddressField from "../../../components/CompoundFields/AddressField";

type Props = {
    onSubmit: (values: object) => void;
    loading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        item: {
            width: '100%',
            marginTop: '15px',
            marginBottom: '0px',
        },
        item_input: {
            backgroundColor: 'white',
            borderRadius: '5px',
        },
        button_line: {
            width: '100%',
            padding: '16px',
        },
        button: {
            float: 'right',
        },
        button_loading: {},
    })
);

const PartnerRegisterForm: React.FunctionComponent<Props> = ({onSubmit, loading}) => {
    const {
        Form,
        meta: {isSubmitting, canSubmit}
    } = useForm({
        onSubmit: async (values: any, instance: any) => {
            onSubmit(values);
        },
        debugForm: Parameters.env === "dev" ? true : false,
    });
    const classes = useStyles();
    return (
        <Form>
            <Grid container spacing={4}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <NameField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} field="firstName" type="first" required/>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <NameField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} field="lastName" type="last" required/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <NameField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} field="company" type="company" required/>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <EmailField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} required />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <PhoneField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} required />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AddressField streetField={"addr"} cityField={"city"} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={clsx(classes.button_line)}>
                        <ActionButton
                            type="submit"
                            className={clsx(classes.button, {
                                [classes.button_loading]: loading,
                            })}
                            disabled={!canSubmit}
                        >
                            {Locale.trans('register.next')}
                        </ActionButton>
                    </div>
                </Grid>
            </Grid>
            <LoaderOverlay loading={isSubmitting} />
        </Form>
    );
};
export default PartnerRegisterForm;
