import * as React from 'react';
import {createStyles, Grid, Theme, Typography} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {Locale} from "ias-lib";
import RegisterForm from "./forms/RegisterForm";
import RegisterLeftPart from './RegisterLeftPart';
import {Alert} from "@material-ui/lab";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            root: {
                // height: '360vh',
                height: '100vh',
            },
            form_part: {
                height: '100%',
                boxShadow: '-2px 0 2px 1px rgb(229, 233, 233)',
                zIndex: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '4%',
                textAlign: 'left',
            },
            form: {
                height: '50%',
                width: '50%',
                textAlign: 'left',
            },
            image_part: {
                maxHeight: '100vh',
                overflow: 'hidden',
                position: 'relative'
            },
            margin: {
                marginTop: '15px',
            },
            paragraph: {
                color: '#0000008A',
                fontSize: '16px',
                fontFamily: 'Lato, sans-serif',
            },
            pageTitle: {
                fontFamily: 'Eesti, sans-serif',
                fontSize: '30px',
                color: '#000000DE',
            }
        }
    ));

type Props = {
    goToNextPage: (arg: object) => void;
    error: string|null;
    loading: boolean;
}

const Register: React.FunctionComponent<Props> = ({goToNextPage, error, loading}) => {
    const classes = useStyles();
    return (
        <Grid container className={clsx(classes.root)}>
            <Grid item className={clsx(classes.image_part)} xs={4}>
                <RegisterLeftPart />
            </Grid>
            <Grid item className={clsx(classes.form_part)} xs={8}>
                <Grid container>
                    <Grid item xs={5} className={clsx(classes.margin)}>
                        <Typography variant="h2" className={classes.pageTitle}>
                            {Locale.trans('register.title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={clsx(classes.margin)}>
                        {error && (
                            <Alert severity={"error"} className={"error"}>{error}</Alert>
                        )}
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={11} className={clsx(classes.margin)}>
                        <Typography variant="body1" className={classes.paragraph}>
                            {Locale.trans('register.paragraph')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={11} className={clsx(classes.margin)}>
                        <RegisterForm loading={loading} onSubmit={goToNextPage} />
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Grid>
        </Grid>
    );
}
export default Register;
