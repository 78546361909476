import * as React from "react";
import {Locale} from "ias-lib";
import MultipleIncotermSelect from "../Incoterms/MultipleIncotermSelect";
import IncotermSelect from "../Incoterms/IncotermSelect";
import "./IncotermField.scss";

type Props = {
    className?: string;
    inputStyle?: string;
    field: string;
    required?: boolean;
    label?: string;
    value?: string; //TODO not working for multiple
    setIncotermValue?: (v: string) => void; //TODO not working for multiple
    disabled?: boolean;
    multiple?: boolean;
}

const IncotermField: React.FunctionComponent<Props> = ({disabled, multiple, className, inputStyle, field, required, label, value, setIncotermValue}) => {

    if(multiple){
       return(
           <MultipleIncotermSelect
               className={`${className} incoterm-field-style`}
               id={field}
               label={label || Locale.trans('incoterms')}
               required={required}
               disabled={disabled}
           />
       );
    }
    return (
        <IncotermSelect
            className={`${className} incoterm-field-style`}
            id={field}
            label={label || Locale.trans('incoterms')}
            required={required}
            selectProps={{
                className: inputStyle
            }}
            value={value}
            setIncotermValue={setIncotermValue}
            disabled={disabled}
        />
    );
};

export default IncotermField;