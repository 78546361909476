import * as React from "react";
import {useState} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useSnackbar} from "notistack";
import {TransportChoice} from "../../../components/Fields/type/TransportChoiceComponent";
import {MovementChoice} from "../../../components/Fields/type/MovementChoiceComponent";
import {MaritimeChoice} from "../../../components/Fields/type/MaritimeChoiceComponent";
import {useForm} from "react-form";
import {Locale} from "ias-lib";
import {Grid} from "@material-ui/core";
import MultipleMovementChoiceComponent from "../../../components/Fields/MultipleMovementChoiceComponent";
import MultipleMaritimeChoiceComponent from "../../../components/Fields/MultipleMaritimeChoiceComponent";
import {TextField} from "shared/src/components/Fields";
import CountryField from "../../../components/Fields/CountryField";
import useCurrentUser from "shared/src/hooks/useCurrentUser";
import IncotermField from "shared/src/components/Fields/IncotermField";
import MultipleTransportChoiceComponent from "shared/src/components/Fields/MultipleTransportChoiceComponent";
import LoaderOverlay from 'shared/src/components/LoaderOverlay/LoaderOverlay';
import Parameters from 'parameters';
import {ButtonBase} from "shared/src/components/Layout/Buttons/Button/Button";
import {SimplePage} from "../../../components/Layout/SimplePage";


const GET_PARTNER_CRITERIA = gql`
    query partner($reference: String!) {
        partner(reference: $reference) {
            id
            selectionCriteria {
                id
                departureCountries
                arrivalCountries
                incotermFrom
                incotermTo
                transportModes
                transportMovement
                transportMaritime
                HSCode
            }
        }
    }
`;

const EDIT_CRITERIA = gql`
        mutation editSelectionCriteria($id: UUID!, $input: SelectionCriteriaInput!) {
            editSelectionCriteria(id: $id, input: $input) {
                id
                departureCountries
                arrivalCountries
                incotermFrom
                incotermTo
                transportModes
                transportMovement
                transportMaritime
                HSCode
            }
        }
    `;


const PartnerSettings: React.FunctionComponent = () => {


    const {enqueueSnackbar} = useSnackbar();
    const {currentUser, loading: userLoading} = useCurrentUser();

    const {data, loading} = useQuery(GET_PARTNER_CRITERIA, {
        variables: {reference: currentUser?.company.reference},//TODO remove reference from here
        fetchPolicy: "cache-and-network"
    });
    const [submit, {loading: submitting}] = useMutation(EDIT_CRITERIA);

    const [transportModes, setTransportModes] = useState<TransportChoice[]>([]);
    const [transportMovement, setTransportMovement] = useState<MovementChoice[]>([]);
    const [transportMaritime, setTransportMaritime] = useState<MaritimeChoice[]>([]);
    const [hsCode, setHsCode] = useState<string>("");

    React.useEffect(() => {
        if (!loading && data) {
            setTransportModes(data.partner.selectionCriteria.transportModes);
            setTransportMovement(data.partner.selectionCriteria.transportMovement);
            setTransportMaritime(data.partner.selectionCriteria.transportMaritime);
            setHsCode(data.partner.selectionCriteria.HSCode);
        }
    }, [loading, data]);


    const handleToggleTransportChoices = (value: TransportChoice) => {
        const isIn = transportModes.find(element => element === value);
        if (isIn) {
            let transports = [...transportModes];
            const index = transports.indexOf(value);
            transports.splice(index, 1);
            setTransportModes(transports);
            if (isIn === "boat") {
                setTransportMaritime([]);
            }
        } else {
            setTransportModes([
                ...transportModes,
                value
            ])
        }
    };

    const handleMovementChoice = (value: MovementChoice) => {
        const isIn = transportMovement.find(element => element === value);
        if (isIn) {
            let movements = [...transportMovement];
            const index = movements.indexOf(value);
            movements.splice(index, 1);
            setTransportMovement(movements)
        } else {
            setTransportMovement([
                ...transportMovement,
                value
            ])
        }
    };

    const handleMaritimeChoice = (value: MaritimeChoice) => {
        const isIn = transportMaritime.find(element => element === value);
        if (isIn) {
            let maritimes = [...transportMaritime];
            const index = maritimes.indexOf(value);
            maritimes.splice(index, 1);
            setTransportMaritime(maritimes)
        } else {
            setTransportMaritime([
                ...transportMaritime,
                value
            ])
        }
    };

    const {
        Form,
    } = useForm({
        onSubmit: async (values: any) => {
            const {hsCode, departureCountries, arrivalCountries, incotermFrom, incotermTo} = values;
            const selectionCriteria = {
                departureCountries,
                arrivalCountries,
                incotermFrom,
                incotermTo,
                transportModes,
                transportMovement,
                transportMaritime,
                HSCode: hsCode,
            };

            submit({
                variables: {
                    id: data.partner.selectionCriteria.id,
                    input: selectionCriteria,
                },
            }).then(() => {
                enqueueSnackbar(Locale.trans('profile.updated.selectionCriteria'), {variant: 'success'});
            }).catch(() => {
                enqueueSnackbar(Locale.trans('error.undefined'), {variant: 'error'});
            })
        },
        debugForm: Parameters.env === "dev",
        defaultValues: data?.partner.selectionCriteria
    });

    return (
        <SimplePage title={Locale.trans("selectionCriteria")}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <span className="sub-title">
                            {Locale.trans('selectionCriteria.originDestination')}
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <CountryField
                                    className={"formItem"}
                                    label={Locale.trans("selectionCriteria.startingCountry")}
                                    field={"departureCountries"}
                                    multiple={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CountryField
                                    className={"formItem"}
                                    label={Locale.trans("selectionCriteria.arrivalCountry")}
                                    field={"arrivalCountries"}
                                    multiple={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <span className="sub-title">
                                    {Locale.trans('selectionCriteria.incoterm')}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <IncotermField
                                    multiple={true}
                                    className={"formItem"}
                                    label={Locale.trans("selectionCriteria.startingIncoterm")}
                                    field={"incotermFrom"}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <IncotermField
                                    multiple={true}
                                    className={"formItem"}
                                    label={Locale.trans("selectionCriteria.arrivalIncoterm")}
                                    field={"incotermTo"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <span className="sub-title">
                                    {Locale.trans('selectionCriteria.transport')}
                                </span>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="selection-criteria-flex">
                                    <div className="transport-choice">
                                        <MultipleTransportChoiceComponent
                                            label={Locale.trans("selectionCriteria.transportMode")}
                                            values={transportModes}
                                            toggleValue={handleToggleTransportChoices}
                                        />
                                    </div>
                                    <div className="movement-choice">
                                        <MultipleMovementChoiceComponent
                                            className="criteria-selection-button flex-margin"
                                            values={transportMovement}
                                            toggleValue={handleMovementChoice}
                                            label={Locale.trans("selectionCriteria.movement")}
                                        />
                                    </div>
                                    <div>
                                        <MultipleMaritimeChoiceComponent
                                            className="criteria-selection-button flex-margin"
                                            values={transportMaritime}
                                            toggleValue={handleMaritimeChoice}
                                            label={Locale.trans("selectionCriteria.maritime")}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={"formItem padded-form-item"}
                                    label={Locale.trans("selectionCriteria.hsCodeExcluded")}
                                    field="hsCode"
                                    value={hsCode}
                                    setValue={setHsCode}
                                />
                            </Grid>
                            <Grid item xs={6}/>
                            <Grid item xs={4}>
                                <ButtonBase
                                    className={"buttonStyle"}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    label={Locale.trans("save")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <LoaderOverlay loading={loading || userLoading || submitting}/>
            </Form>
        </SimplePage>
    );
};
export default PartnerSettings;