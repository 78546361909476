import {gql} from "@apollo/client";


const GET_COUNTRIES = gql`
query countries {
    countries {
        id
        fips
        iso
        tld
        countryName
        suggested
    }
}
`;

const GET_CITIES = gql`
query cities ($countryIso: String, $subString: String, $isPort: Boolean) {
    cities (countryIso: $countryIso, subString: $subString, isPort: $isPort) {
        id
        fullName
        isPort
    }
}
`;

export {
    GET_COUNTRIES,
    GET_CITIES
}
