import React from "react";
import Button from '@material-ui/core/Button';
import './Button.scss';
import { CircularProgress, Tooltip } from "@material-ui/core";


type Props = {
    color: 'primary'|'secondary'|'default';
    variant: 'outlined'|'contained'|'text';
    label: string | React.ReactNode;
    startIcon?: React.ReactNode;
    disabled?: boolean;
    loading?: boolean;
    onClick?: (() => void) | ((e: any) => void);
    type?: "button" | "submit" | "reset";
    size?: "large"|"medium"|"small";
    tooltip?: string;
    className?: string;
}

export const ButtonBase: React.FunctionComponent<Props> = ({onClick, color, variant, startIcon, label, disabled, loading, type, size, tooltip, className}) => {
    if(size===undefined) {
        size = "large";
    }
    return (
        <Tooltip title={tooltip ? tooltip : ""} arrow>
            <Button
                className={`button ${variant} ${disabled ? "disabled" : color} ${typeof(label) === "string" ? "padded" : ""} ${className} ${size}`}
                color={color}
                disableRipple
                disableElevation
                disableFocusRipple
                startIcon={loading ? <CircularProgress size={20} /> : 
                    startIcon ? startIcon : null
                }
                variant={disabled ? 'contained' : variant}
                disabled={disabled || loading}
                onClick={onClick}
                type={type}
                size={size}
            >
                    {color !== 'default' ?
                    <>
                        {label}
                    </>
                :
                    <span className="default-label">
                        {label}
                    </span>
                }
            </Button>
        </Tooltip>
    )
}