import * as React from 'react';
import {Button} from "@material-ui/core";
import {Locale} from "ias-lib";
import IconTransportType from "./IconTransportType";
import './TransportChoiceButton.scss';

type Props = {
    value: "" | "boat" | "plane" | "train";
    selected: boolean;
    onClick: (event: any) => void;
    disabled?: boolean;
    className?: string;
}

export const TransportChoiceButton: React.FunctionComponent<Props> = ({value, selected, onClick, disabled, className}) => {
    return (
        <Button
            className={`transport-choice-button ${selected ? "selected" : ""} ${className ? className : ''}`}
            variant="outlined"
            value={value}
            aria-label={Locale.trans("transport.type.boat")}
            disableRipple
            disableElevation
            disabled={disabled}
            disableFocusRipple
            onClick={onClick}
        >
            <IconTransportType type={value} className={`transport-icon ${selected ? "selected" : ""}`}/>
        </Button>
    )
};

