import * as React from 'react';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {InputLabel} from "@material-ui/core";
import clsx from "clsx";
import { TransportChoice } from 'shared/src/components/Fields/type/TransportChoiceComponent';
import {TransportChoiceButton} from "shared/src/components/Layout/Buttons/TransportChoiceButton/TransportChoiceButton";
import "./MultipleTransportChoiceComponent.scss";

type MultipleTransportChoiceProps = {
    values: TransportChoice[];
    toggleValue: (arg: TransportChoice) => void;
    label?: string;
    disabled?: boolean;
}

const useStyles1 = makeStyles(() =>
    createStyles({
        toggleContainer: {
            position: 'relative',
            display: 'flex',
        },
        button_form_label: {
            position: 'absolute',
            top: '-23px',
            fontFamily: "Lato, sans-serif",
            fontSize: '12px',
        },
    })
);

const MultipleTransportChoiceComponent: React.FunctionComponent<MultipleTransportChoiceProps> = ({values, toggleValue, label, disabled}) => {
    const classes = useStyles1();

    return (
        <div className={classes.toggleContainer + " multiple-transport-choice"}>
            {label && (<InputLabel
                className={clsx(classes.button_form_label)}>{label}</InputLabel>)}
            <TransportChoiceButton
                value="boat"
                disabled={disabled}
                onClick={() => toggleValue("boat")}
                selected={values.includes("boat")}
            />
            <TransportChoiceButton
                value="plane"
                disabled={disabled}
                onClick={() => toggleValue("plane")}
                selected={values.includes("plane")}
            />
            <TransportChoiceButton
                value="train"
                disabled={disabled}
                onClick={() => toggleValue("train")}
                selected={values.includes("train")}
            />
        </div>
    )
};
export default MultipleTransportChoiceComponent;

