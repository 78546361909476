import * as React from "react";
import {useState} from "react";
import {Locale} from "ias-lib";
import MultipleAutocompleteField from "../../../../shared/src/components/Fields/MultipleAutocompleteField";

type Props = {
    label: string;
    id: string,
    error?: string | null,
    className?: string;
    required?: boolean;
    disabled?: boolean;
}

type Option = {
    value: any;
    name: string;
    category?: string;
}

const MultipleIncotermSelect: React.FunctionComponent<Props> = ({disabled, label, id, className, required}) => {



    const [incoterms] = useState<Option[]>([
        {value: "EXW", name: Locale.trans("incoterms.EXW")},
        {value: "FCA", name: Locale.trans("incoterms.FCA")},
        {value: "FAS", name: Locale.trans("incoterms.FAS")},
        {value: "FOB", name: Locale.trans("incoterms.FOB")},
        {value: "CFR", name: Locale.trans("incoterms.CFR")},
        {value: "CIF", name: Locale.trans("incoterms.CIF")},
        {value: "CPT", name: Locale.trans("incoterms.CPT")},
        {value: "DAP", name: Locale.trans("incoterms.DAP")},
        {value: "DAT", name: Locale.trans("incoterms.DAT")},
        {value: "DDP", name: Locale.trans("incoterms.DDP")},
    ]);



    return (
        <>
            <MultipleAutocompleteField
                label={label}
                options={incoterms}
                field={id}
                disabled={disabled}
                required={required}
                className={className}

            />
        </>
    );
};

export default MultipleIncotermSelect;