import * as React from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import { Locale } from 'ias-lib';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import {
    AppBar,
    Badge,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    TextField,
} from '@material-ui/core';
import MenuComponent from "shared/src/components/Menu/Menu";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import speedometer from 'images/speedometer-3.svg';
import bell from 'shared/src/images/bell.svg';
import bill from 'images/bill-2.svg';
import archive from 'images/archive-2.svg';
import check from 'images/check-in-2.svg';
import tags from 'images/tags-stack.svg';
import contact from 'images/contact-86.svg';
import users from 'images/users-mm.svg';
import speedometerSelected from 'images/speedometer-3-selected.svg';
import archiveSelected from 'images/archive.svg';
import billSelected from 'images/bill-2-selected.svg';
import checkSelected from 'images/check-in-2-selected.svg';
import tagsSelected from 'images/tags-stack-selected.svg';
import usersSelected from 'images/users-mm-selected.svg';
import ListItem from '@material-ui/core/ListItem';
import { AuthConsumer, useLogout } from 'shared/src/contexts/AuthContext';
import {useHistory} from 'react-router';
import {useIsGranted, CLIENT, PARTNER, ADMIN_EMPLOYEE} from '../../services/useIsGranted';
import {useQuery} from "@apollo/client";
import Parameters from '../../parameters';
import {SLOW_POLL_RATE} from "../../const/PollRate";
import {GET_NOTIFICATIONS} from "./gql";
import {Notifications} from "shared/src/components/Layout/Notification/Notifications";
import Logo from "shared/src/components/Layout/AppBarComponents/Logo";
import {Search} from "shared/src/components/Search/Search";
import {ButtonBase} from "shared/src/components/Layout/Buttons/Button/Button";
import "./AppBarComponent.scss";
import LanguageSelect from 'shared/src/components/Country/LanguageSelect';
import {useMenuManagement} from "shared/src/contexts/MenuManagementContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            background: 'white',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            boxShadow: 'none',
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        appBarIcon: {
            color: '#101e2c',
            marginRight: 0,
            borderRadius: '15px',

            "&:hover": {
                backgroundColor: 'rgba(0,0,0,0.07)'
            }
        },
        label: {
            fontWeight: 'bold',
        },
        searchMenu: {
            backgroundColor: 'white',
            borderRadius: '2px',
            color: '#9fa5ab',
        },
        logout: {
            color: '#00b796',
            fontWeight: 'bold',
        },
        menuOpen: {
            background: 'rgba(0,0,0,0.07)',
        },
        profileMenu: {
            borderRadius: '8px',
            boxShadow: '0px 2px 2px #0000000D',
            border: '1px solid rgba(62,62,62,0.1)',
            width: '230px',
            margin: '0 -8px',
            color: '#818890',
            fontFamily: 'Eesti, sans-serif',
        },
    }),
);

type Props = {};

const AppBarComponent: React.FunctionComponent<Props> = () => {
    const { isGranted, currentUser } = useIsGranted();
    const logout = useLogout();
    const classes = useStyles();

    const {isMenuOpen, toggleMenuOpen} = useMenuManagement();

    const [
        profileMenuOpenAnchorEl,
        setProfileMenuOpenOpenAnchorEl,
    ] = React.useState<null | HTMLElement>(null);


    const handleMenuToggle = () => {
        toggleMenuOpen();
    };
    const history = useHistory();
    const companyStatus = currentUser?.company?.status || "undefined";

    let MenuItemsDesktop: any[] = [];
    if (isGranted(CLIENT)) {
        MenuItemsDesktop = [
            companyStatus === 'active' && {
                name: Locale.trans('menu.dashboard'),
                icon: <img src={speedometer} alt="speedometer-logo" />,
                iconSelected: (
                    <img src={speedometerSelected} alt="speedometer-logo" />
                ),
                url: '/',
            },
            companyStatus === 'active' && {
                name: Locale.trans('menu.quotations'),
                icon: <img src={tags} alt="quotations-logo" />,
                iconSelected: <img src={tagsSelected} alt="quotations-logo" />,
                url: '/quotations',
            },
            companyStatus === 'active' && {
                name: Locale.trans('menu.transport'),
                icon: <img src={check} alt={'transport logo'} />,
                iconSelected: (
                    <img src={checkSelected} alt={'transport logo'} />
                ),
                url: '/transport',
            },
            companyStatus === 'active' && {
                name: Locale.trans('menu.contacts'),
                icon: <img src={users} alt={'contacts logo'} />,
                iconSelected: <img src={usersSelected} alt={'contacts logo'} />,
                url: '/contacts',
            },
            ['active', 'blocked'].includes(companyStatus) && {
                name: Locale.trans('menu.bills'),
                icon: <img src={bill} alt={'bills logo'} />,
                iconSelected: <img src={billSelected} alt={'bills logo'} />,
                url: '/bills',
            },
        ];
    } else if (isGranted(PARTNER)) {
        MenuItemsDesktop = [
            companyStatus === 'active' && {
                name: Locale.trans('menu.dashboard'),
                icon: <img src={speedometer} alt="speedometer-logo" />,
                iconSelected: (
                    <img src={speedometerSelected} alt="speedometer-logo" />
                ),
                url: '/',
            },
            companyStatus === 'active' && {
                name: Locale.trans('menu.requests'),
                icon: <img src={archive} alt={'bills logo'} />,
                iconSelected: <img src={archiveSelected} alt={'bills logo'} />,
                url: '/requests',
            },
            companyStatus === 'active' && {
                name: Locale.trans('menu.offers'),
                icon: <img src={tags} alt="quotations-logo" />,
                iconSelected: <img src={tagsSelected} alt="quotations-logo" />,
                url: '/offers',
            },
            companyStatus === 'active' && {
                name: Locale.trans('menu.expeditions'),
                icon: <img src={check} alt={'transport logo'} />,
                iconSelected: (
                    <img src={checkSelected} alt={'transport logo'} />
                ),
                url: '/expeditions',
            },
            ['active', 'blocked'].includes(companyStatus) && {
                name: Locale.trans('menu.bills'),
                icon: <img src={bill} alt={'bills logo'} />,
                iconSelected: <img src={billSelected} alt={'bills logo'} />,
                url: '/bills',
            },
        ];
    }

    const MenuItemsMobile = [
        ...MenuItemsDesktop,
        {
            render: () => {
                return (
                    <ListItem button key={'search'} onClick={() => {}}>
                        <Grid
                            container
                            spacing={1}
                            alignItems="flex-end"
                            className={classes.searchMenu}
                        >
                            <Grid item>
                                <SearchOutlinedIcon />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="input-with-icon-textfield"
                                    label={Locale.trans('menu.search')}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                );
            },
        },
    ];

    const {data: notificationsData, refetch} = useQuery(GET_NOTIFICATIONS, {
        pollInterval: Parameters.env === "dev" ? undefined : SLOW_POLL_RATE,
        fetchPolicy: "cache-and-network"
    });


    const handleProfileMenuClose = () => {
        setProfileMenuOpenOpenAnchorEl(null);
    };
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenuOpenOpenAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';

    const notifications = notificationsData ? notificationsData.notifications.notifications : [];

    const profileMenuItems = [{
        name: Locale.trans('profile.my'),
        url: '/profile/my'
    }];
    if(isGranted(ADMIN_EMPLOYEE)) {
        profileMenuItems.push({
            name: Locale.trans('profile.company'),
            url: '/profile/company'
        });
    }
    profileMenuItems.push({
        name: currentUser && currentUser.company.type === 'client' ?
            Locale.trans('profile.settingsClient')
            :
            Locale.trans('profile.settingsPartner'),
        url: '/profile/settings'
    }, {
        name: Locale.trans('profile.notifications'),
        url: '/profile/notifications'
    });


    return (
        <AuthConsumer>
            {({ authToken}) => {
                return (
                    <>
                        <AppBar
                            position="fixed"
                            className={clsx(classes.appBar)}
                        >
                            <Toolbar className={"app-bar"}>
                                <Grid container spacing={2}>
                                    <Grid item className={`logo-container ${(isMenuOpen ? "open" : 'closed')}`}>
                                        <Logo
                                            isMenuOpen={isMenuOpen}
                                            handleMenuToggle={handleMenuToggle}
                                        />
                                    </Grid>

                                    <Grid item className={"desktop search-container"}>
                                        {companyStatus === 'active' && (
                                            <Search/>
                                        )}
                                    </Grid>
                                    {companyStatus === 'active' && isGranted(CLIENT) && (
                                        <Grid item className={"desktop buttons-container"}>
                                            <ButtonBase
                                                className={"quotation-button"}
                                                label={Locale.trans('quotation')}
                                                variant="contained"
                                                startIcon={<AddCircleOutlineOutlinedIcon />}
                                                color="primary"
                                                onClick={() =>
                                                    history.push(
                                                        '/quotations/new',
                                                    )
                                                }
                                            />
                                        </Grid>
                                    )}
                                    <Grid item className={"desktop buttons-container"}>
                                        <LanguageSelect/>
                                    </Grid>
                                    <Grid item className={"desktop buttons-container"}>
                                        {companyStatus !== 'archived' && (
                                            <Notifications
                                                buttonClassName={"appBarIcon"}
                                                notifications={notifications}
                                                total={notificationsData ? notificationsData.notifications.total : 0}
                                                refetch={refetch}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item className={"desktop"}>
                                        <div
                                            className="profile-container clickable"
                                            onClick={handleProfileMenuOpen}
                                            >
                                        <IconButton
                                            edge="end"
                                            className={clsx({
                                                [classes.menuOpen]:profileMenuOpenAnchorEl
                                            }, classes.appBarIcon)}
                                            aria-label="account of current user"
                                            aria-controls={menuId}
                                            aria-haspopup="true"
                                            color="inherit"
                                            disableRipple
                                            >
                                            <img
                                                className={Boolean(profileMenuOpenAnchorEl) ? "menu-account-open" : undefined}
                                                src={contact}
                                                alt={'logo user'}
                                            />
                                        </IconButton>
                                        </div>
                                    </Grid>
                                <div className={classes.sectionMobile}>
                                    <IconButton
                                        className={clsx(classes.appBarIcon)}
                                        aria-label="show 4 new notifications"
                                        color="inherit"
                                        disableRipple
                                    >
                                        <Badge
                                            badgeContent={4}
                                            color="secondary"
                                        >
                                            <img
                                                src={bell}
                                                alt={'logo notification'}
                                            />
                                        </Badge>
                                    </IconButton>
                                    <IconButton
                                        edge="end"
                                        className={clsx(classes.appBarIcon)}
                                        aria-label="account of current user"
                                        aria-controls={menuId}
                                        aria-haspopup="true"
                                        onClick={handleProfileMenuOpen}
                                        color="inherit"
                                        disableRipple
                                    >
                                        <img src={contact} alt={'logo user'} />
                                    </IconButton>
                                </div>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <MenuComponent
                            classes={[classes.sectionDesktop]}
                            isOpen={isMenuOpen}
                            toggleOpen={handleMenuToggle}
                            menuItems={MenuItemsDesktop.filter((m) => !!m)}
                            mobile={false}
                        />
                        <MenuComponent
                            classes={[classes.sectionMobile]}
                            isOpen={isMenuOpen}
                            toggleOpen={handleMenuToggle}
                            menuItems={MenuItemsMobile.filter((m) => !!m)}
                            mobile={true}
                        />
                        {authToken && (
                            <>
                                <Menu
                                    anchorOrigin={{
                                        horizontal: 'left',
                                        vertical: 'bottom',
                                    }}
                                    classes={{
                                        paper: classes.profileMenu,
                                    }}
                                    anchorReference="anchorEl"
                                    id="simple-menu"
                                    anchorEl={profileMenuOpenAnchorEl}
                                    keepMounted
                                    open={Boolean(profileMenuOpenAnchorEl)}
                                    onClose={handleProfileMenuClose}
                                    getContentAnchorEl={null}
                                >
                                    <div className={"profile-menu-container"}>
                                        {companyStatus !== 'archived' &&
                                        profileMenuItems.map((item) => (
                                                <MenuItem
                                                    onClick={() => {
                                                        history.push(item.url);
                                                        handleProfileMenuClose();
                                                    }}
                                                    disableRipple
                                                    key={item.url}
                                                    className={`${window.location.href.endsWith(item.url) ? 'active' : ''} menu-item`}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        <hr
                                            key={'dividor'}
                                            style={{
                                                backgroundColor: '#ececec',
                                                border: '1px solid #ececec',
                                                marginBottom: '-2px',
                                                marginTop: '-2px',
                                            }} />
                                        <MenuItem
                                            className={classes.logout + " menu-item-logout"}
                                            disableRipple
                                            onClick={() => {
                                                handleProfileMenuClose();
                                                logout();
                                            }}
                                            >
                                            {Locale.trans('logout')}
                                        </MenuItem>

                                    </div>
                                </Menu>
                            </>
                        )}
                    </>
                );
            }}
        </AuthConsumer>
    );
};
export default AppBarComponent;
