import * as React from 'react';
import {createStyles, Grid, Typography} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {Locale} from "ias-lib";
import RegisterLeftPart from './RegisterLeftPart';


const useStyles = makeStyles(() =>
    createStyles({
            root: {
                height: '100vh'
            },
            form_part: {
                height: '100%',
                boxShadow: '-2px 0 2px 1px rgb(229, 233, 233)',
                zIndex: 50,
                display: 'flex',
                justifyContent: 'center',
                padding: '4%',
                textAlign: 'left',
            },
            form: {
                height: '50%',
                width: '50%',
                textAlign: 'left',
            },
            image_part: {
                maxHeight: '100vh',
                overflow: 'hidden',
                position: 'relative'
            },
            margin: {
                marginTop: '60px',
            },
            title: {
                fontFamily: 'Eesti, sans-serif',
                fontSize: '35px',
                color: '#101E2C',
            },
            typography: {
                marginTop: '21px',
                color: '#0000008A',
                fontSize: '18px',
                fontFamily: 'Lato, sans-serif',
            },
            bold: {
                fontWeight: 'bolder',
            }
        }
    ));

type Props = {}

const Success: React.FunctionComponent<Props> = () => {
    const classes = useStyles();
    return (
            <Grid container className={clsx(classes.root)}>
                <Grid item className={clsx(classes.image_part)} xs={4}>
                    <RegisterLeftPart />
                </Grid>
                <Grid item className={clsx(classes.form_part)} xs={8}>
                <Grid container>
                    <Grid item xs={12} className={clsx(classes.margin)}>
                        <Typography variant="h2" className={classes.title}>
                            {Locale.trans('register.success.title')}
                        </Typography>
                        <Typography variant="body1" className={classes.typography}>
                            {Locale.trans('register.success.paragraph')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
};
export default Success;
