import React from 'react';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {InputLabel} from "@material-ui/core";
import clsx from "clsx";
import { MovementChoice } from './type/MovementChoiceComponent';
import { TransportTextButton } from 'shared/src/components/Layout/Buttons/TransportTextButton/TransportTextButton';

type MultipleMovementChoiceProps = {
    values: MovementChoice[];
    toggleValue: (arg: MovementChoice) => void;
    label?: string;
    type?: "full"|"transparent";
    className?: string;
}

const useStyles1 = makeStyles(() =>
    createStyles({
        toggleContainer: {
            position: 'relative',
            display: 'flex',
            height: '100%',
        },
        button_form_label: {
            position: 'absolute',
            top: '-23px',
            fontFamily: "Lato, sans-serif",
            fontSize: '12px',
        },
    })
);

const MultipleMovementChoiceComponent: React.FunctionComponent<MultipleMovementChoiceProps> = ({values, toggleValue, label, type, className}) => {

    const classes = useStyles1();
    if(!type) type="full";

    const isToggled = (value: MovementChoice) => {
        return values.find(element => element === value)
    }


    return (
        <div className={classes.toggleContainer}>
            {label && (<InputLabel
                className={clsx(classes.button_form_label)}>{label}</InputLabel>)}
            <TransportTextButton
                className={className}
                value="import"
                selected={isToggled('import') ? true : false}
                onClick={() => toggleValue("import")}
            />
            <TransportTextButton
                className={className}
                value="export"
                selected={isToggled('export') ? true : false}
                onClick={() => toggleValue("export")}
            />
            <TransportTextButton
                className={className}
                value="crosstrade"
                selected={isToggled('crosstrade') ? true : false}
                onClick={() => toggleValue("crosstrade")}
            />
        </div>
    )
};
export default MultipleMovementChoiceComponent;

