import * as React from "react";

type Props = {
    refetch?: () => void;
    error?: any;
}

const ErrorView: React.FunctionComponent<Props> = ({refetch, error}) => {
    return (
        <>
            erreur
        </>
    );
};
export default ErrorView;