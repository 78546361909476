import * as React from 'react';
import {useEffect, useState} from 'react';
import {createStyles, Grid, Theme} from "@material-ui/core";
import {TextField} from "shared/src/components/Fields";
import clsx from "clsx";
import {Locale} from "ias-lib";
import CountryField from "../Fields/CountryField";
import {makeStyles} from "@material-ui/core/styles";
import CityField from "../Fields/CityField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '25px',
        },
        item: {
            width: '100%',
            marginTop: '15px',
            marginBottom: '0px',
        },
        item_input: {
            backgroundColor: 'white',
            borderRadius: '5px',
        },
        button_line: {
            width: '100%',
            padding: '16px',
        },
        button: {
            float: 'right',
        },
        button_loading: {},
        marginTop: {
            marginTop: 15,
        },
    })
);

type Props = {
    hasCountry?: boolean;
    streetField?: string;
    compAddressField?: string;
    cityField?: string;
    postalCodeField?: string;
    countryField?: string;
    country?: string;
    hasCountryValue?: boolean;
    unlocode?: boolean;
    disabled?: boolean;
    cityFreeText?: boolean;
}

const AddressField: React.FunctionComponent<Props> = ({
                                                          unlocode,
                                                          country,
                                                          hasCountry,
                                                          streetField,
                                                          cityField,
                                                          compAddressField,
                                                          countryField,
                                                          postalCodeField,
                                                          disabled,
                                                          cityFreeText,
                                                      }) => {
    const classes = useStyles();
    const [countryValue, setCountryValue] = useState<string>(country || '');

    useEffect(() => {
        if(country) {
            setCountryValue(country);
        }
    }, [country]);

    if(hasCountry === undefined) hasCountry = true;

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        inputStyle={clsx(classes.item_input)}
                        className={clsx(classes.item)}
                        field={streetField || "address"}
                        label={Locale.trans('address.address')}
                        required
                        disabled={disabled}
                        autocomplete="chrome-off"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        inputStyle={clsx(classes.item_input)}
                        className={clsx(classes.item)}
                        field={compAddressField || "compAddress"}
                        label={Locale.trans('address.compAddress')}
                        autocomplete="chrome-off"
                        disabled={disabled}
                    />
                </Grid>
                {hasCountry &&
                <Grid item xs={12}>
                    <CountryField
                        className={clsx(classes.item)}
                        inputStyle={clsx(classes.item_input)}
                        field={countryField || "country"}
                        required
                        countryValue={countryValue}
                        setCountryValue={setCountryValue}
                        disabled={disabled}
                    />
                </Grid>
                }
                <Grid item xs={6}>
                    {cityFreeText ?
                        <TextField
                            inputStyle={clsx(classes.item_input)}
                            className={clsx(classes.item)}
                            field={cityField || "address.city"}
                            label={Locale.trans('address.city')}
                            required
                            disabled={disabled}
                            autocomplete="chrome-off"
                        />
                    :
                        <CityField
                            className={clsx(classes.item)}
                            inputStyle={clsx(classes.item_input)}
                            field={cityField || "address.city"}
                            label={Locale.trans('address.city')}
                            required
                            useUnlocode={unlocode}
                            countryIso={countryValue||''}
                            disabled={disabled}
                        />
                    }
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        inputStyle={clsx(classes.item_input)}
                        className={clsx(classes.item)}
                        field={postalCodeField || "postalCode"}
                        label={Locale.trans('address.postalCode')}
                        disabled={disabled}
                        autocomplete="chrome-off"
                        maxLength={17}
                    />
                </Grid>
            </Grid>

        </>
    );
}
export default AddressField;
