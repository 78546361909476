import * as React from 'react';
import {Typography} from "@material-ui/core";

type Props = {}

const Main: React.FunctionComponent<Props> = () => {
    return (
        <>
            <Typography>
                Cette interface n'est pas faite pour vous. Veuillez vous connecter sur le back-office.
            </Typography>
        </>
    );
}
export default Main;
