import * as React from 'react';
import {AppBar, Button, createStyles, Theme, Typography, withStyles} from "@material-ui/core";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../images/logo couleur.png";
import {Locale} from "ias-lib";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, Switch} from "react-router-dom";
import Register from "./Register";
import Success from "./Success";
import {useState} from "react";
import gql from 'graphql-tag';
import {useMutation} from '@apollo/client';
import Parameters from "../../parameters";

const REGISTER = gql`
    mutation createClient($input: ClientInput!) {
        createClient(input: $input) {
            id
            name
            isMain
        }
    }
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            appBar: {
                background: 'white',
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                paddingRight: '50px',
            },
            title: {
                flexGrow: 1,
                textAlign: 'right',
                paddingRight: '10px',
                fontFamily: 'Lato, sans-serif',
                fontSize: '15px',
                color: '#101E2C',
            },
            link: {
                fontFamily: 'Lato, sans-serif',
                fontSize: '15px',
                color: '#00B796',
            },
        }
    ));

const RegisteredButton = withStyles(() => ({
    root: {
        color: '#00B796',
        paddingLeft: 0,
        padding: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            background: 'transparent',
        }
    },
}))(Button);

type Props = {}

const Main: React.FunctionComponent<Props> = () => {
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = useState<1 | 2>(1);
    const [error, setError] = useState<string | null>(null);
    const [submit, {loading}] = useMutation(REGISTER);


    return (
        <>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar)}
            >
                <Toolbar>
                    <img
                        style={{marginLeft: '0',}}
                        src={logo}
                        alt={Locale.trans("logo.fleetever")}
                        height={"50%"}
                    />
                    <Typography className={clsx(classes.title)} variant="h6" noWrap>
                        {Locale.trans('register.already_registered')}
                    </Typography>
                    <RegisteredButton disableRipple disableElevation className={classes.link}
                                      onClick={() => history.push('/login')}>
                        {Locale.trans('register.go_to_connect')}
                    </RegisteredButton>
                </Toolbar>
            </AppBar>
            <Switch>
                {page === 1 && (
                    <Register
                        goToNextPage={(values: any) => {
                            submit({
                                variables: {
                                    input: {
                                        name: values.company,
                                        country: values.country,
                                        contact: {
                                            firstName: values.firstName,
                                            lastName: values.lastName,
                                            email: values.email,
                                            phoneNumber: values.phone,
                                        },
                                        transportModes: values.transport,
                                        numberOperations: values.number_operation,
                                        knownBy: values.known,
                                    }
                                },
                            }).then(() => {
                                setPage(2);
                            }).catch((e) => {
                                console.error(e);
                                setError(Locale.trans("register.error", {SUPPORT: Parameters.supportMail}));
                            })
                        }}
                        error={error}
                        loading={loading}
                    />
                )}
                {page === 2 && (<Success/>)}
            </Switch>
        </>
    );
};
export default Main;
