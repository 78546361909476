import React, {ChangeEvent, ReactNode, useEffect, useState} from 'react';
import {Locale} from "ias-lib";
import {useQuery} from "@apollo/client";
import {GET_COUNTRIES} from "./gql";
import {Logger} from "../../services/Logger";
import {MultipleAutocompleteField} from "../Fields/MultipleAutocompleteField";
import {AutocompleteField} from "../Fields";

type Props = {
    label: string;
    id: string,
    className?: string;
    required?: boolean;
    disabled?: boolean;
    selectProps?: any; //TODO
    data: Country[];
    loading: boolean;
    variant?: "standard" | 'outlined';
}

// from https://bitbucket.org/atlassian/design-system-mirror/src/master/design-system/select/src/data/countries.ts
interface CountryType {
    value: string;
    name: string;
    category?: string;
}

export function MultipleCountrySelect({
                           loading,
                           label,
                           id,
                           className,
                           required,
                           disabled,
                           selectProps,
                           data,
                           variant,
                       }: Props) {

    const [options, setOptions] = useState<CountryType[]>([]);

    useEffect(() => {
        setOptions(data.map((country: Country, index) => {
            return {
                name: `${Locale.trans(`countries.${country.iso}`)} (${country.iso})`,
                value: country.iso,
                category: country.suggested ? 'suggested' : undefined
            }
        }));
    }, [data]);


    return (
        <MultipleAutocompleteField
            label={label}
            className={className}
            options={options}
            disabled={disabled}
            field={id}
            loading={loading}
            required={required}
            selectProps={selectProps}
            variant={variant}

        />
    );


}

type WrapperProps = {
    label: string;
    id: string,
    className?: string;
    required?: boolean;
    selectProps?: any; //TODO
    disabled?: boolean;
    suggestedCountries?: string[];
    variant?: "standard" | "outlined";
}
const EU_COUNTRIES = ["BE", "EL", "LT", "PT", "BG", "ES", "LU", "RO", "CZ", "FR", "HU", "SI", "DK", "HR", "MT", "SK", "DE", "IT", "NL", "FI", "EE", "CY", "AT", "SE", "IE", "LV", "PL"];
const Wrapper: React.FunctionComponent<WrapperProps> = ({
                                                            label,
                                                            disabled,
                                                            id,
                                                            className,
                                                            required,
                                                            selectProps,
                                                            suggestedCountries,
                                                            variant,
                                                        }) => {
    const {data, error, loading} = useQuery(GET_COUNTRIES, {
        fetchPolicy: "cache-and-network"
    });
    if (error) {
        Logger.logError(error);
    }
    const countriesArray = [...(data?.countries || [])]
        .map(country => ({
            ...country,
            suggested: !!(suggestedCountries||[]).find(c => c === country.iso)
        }))
        .sort((a: Country, b: Country) => {
        if((suggestedCountries || []).find(c => c === a.iso) && !(suggestedCountries || []).find(c => c === b.iso)) {
            return -1;
        }
        if(! (suggestedCountries || []).find(c => c === a.iso) && (suggestedCountries || []).find(c => c === b.iso)) {
            return 1;
        }

        if (a.iso === "FR") {
            return -1;
        }
        if (b.iso === "FR") {
            return 1;
        }
        if (EU_COUNTRIES.find(c => c === a.iso) && EU_COUNTRIES.find(c => c === b.iso)) {
            if (Locale.trans(`countries.${a.iso}`) < Locale.trans(`countries.${b.iso}`)) {
                return -1;
            }
            if (Locale.trans(`countries.${a.iso}`) > Locale.trans(`countries.${b.iso}`)) {
                return 1;
            }
            return 0;
        }
        if (EU_COUNTRIES.find(c => c === a.iso)) {
            return -1;
        }
        if (EU_COUNTRIES.find(c => c === b.iso)) {
            return 1;
        }
        if (Locale.trans(`countries.${a.iso}`) < Locale.trans(`countries.${b.iso}`)) {
            return -1;
        }
        if (Locale.trans(`countries.${a.iso}`) > Locale.trans(`countries.${b.iso}`)) {
            return 1;
        }
        return 0;
    });

    const correctCountries = countriesArray.filter((country: Country) => country.iso !== '-' && country.fips !== '-' && country.iso !== '' && country.fips !== '');

    return (
        <MultipleCountrySelect
            disabled={disabled}
            label={label}
            id={id}
            className={className}
            required={required}
            selectProps={selectProps}
            data={correctCountries}
            loading={loading}
            variant={variant ? variant : "outlined"}
        />
    );
};

export default Wrapper;

