
import { FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';
import './CustomSwitch.scss';

type Props = {
    isChecked: boolean;
    handleChange: () => void;
    label: string;
    disabled?: boolean;
    className?: string;
}

const CustomSwitch = ({isChecked, handleChange, label, disabled, className}: Props) => {
    return (
        <div className={"fleetever-custom-slider " + className}>
            <FormControlLabel
                control={<Switch color="primary" disableRipple checked={isChecked} disabled={disabled} onChange={handleChange} />}
                label={label}
            />
        </div>
    )
}

export default CustomSwitch;