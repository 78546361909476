import * as React from 'react';
import {useField} from "react-form";
import CustomSwitch from "shared/src/components/Layout/CustomSwitch";
import {ChangeEvent} from "react";

type FieldsProps = {
    className?: string;
    field: string;
    required?: boolean;
    label?: string
    disabled?: boolean;
    value?: boolean;
}

const SwitchField: React.FunctionComponent<FieldsProps>  = ({value: defaultValue, disabled, className, field, required, label}) => {
    const {
        getInputProps
    } = useField(field, {
        defaultValue: defaultValue,
    });
    const {value, onChange} = getInputProps();

    const handleChange = () => {
        onChange( {
            target: {
                value: !value,
            }
        } as unknown as ChangeEvent);
    };

    return (
        <CustomSwitch
            isChecked={value}
            handleChange={() => handleChange()}
            label={label ? label : ''}
            disabled={disabled}
        />
    )
};

export default SwitchField;
