import * as React from 'react';
import clsx from "clsx";
import {Locale} from "ias-lib";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {useField, useForm} from "react-form";
import PhoneField from "../../../components/Fields/PhoneField";
import EmailField from "../../../components/Fields/EmailField";
import NameField from "../../../components/Fields/NameField";
import {LoaderOverlay} from "../../../components";
import {TransportChoice} from "../../../components/Fields/type/TransportChoiceComponent";
import { SelectField } from 'shared/src/components/Fields';
import { ButtonBase } from 'shared/src/components/Layout/Buttons/Button/Button';
import MultipleTransportChoiceComponent from 'shared/src/components/Fields/MultipleTransportChoiceComponent';
import './RegisterForm.scss';

type Props = {
    onSubmit: (values: object) => void;
    loading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        item: {
            width: '100%',
            marginTop: '15px',
            marginBottom: '0px',
        },
        item_input: {
            backgroundColor: 'white',
            borderRadius: '5px',
        },
        button: {
            float: 'right',
        },
        button_loading: {},
        span: {
            fontSize: '16px',
            color: '#000000DE',
        },
    })
);

const RegisterForm: React.FunctionComponent<Props> = ({onSubmit, loading}) => {
    const {
        Form,
        meta: {isSubmitting, canSubmit}
    } = useForm({
        onSubmit: async (values: any, instance: any) => {
            onSubmit(values);
        },
    });

    const classes = useStyles();
    return (
        <Form>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <NameField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} field="firstName" type="first" required/>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <NameField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} field="lastName" type="last" required/>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <NameField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} field="company" type="company" required/>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    {/* <CountryField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} field={"country"} required/> */}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <EmailField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} required />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <PhoneField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)} required />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <span className={classes.span}>{Locale.trans('register.transport.mode')}</span>
                    <TransportType />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <span className={classes.span}>{Locale.trans('register.number_operation')}</span>
                    <SelectField
                        choices={['1-10', '11-50', '51-100', '100+']}
                        field="number_operation"
                        className={clsx(classes.item)}
                        inputStyle={clsx(classes.item_input)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <SelectField
                        choices={[Locale.trans('register.known_by'), 'test', 'test1', 'test2', 'test3']}
                        field="known"
                        className={clsx(classes.item)}
                        inputStyle={clsx(classes.item_input)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="register-button-grid">
                    <div className="register-button-line">
                        <ButtonBase
                            color="primary"
                            variant="contained"
                            label={Locale.trans('register.begin')}
                            type="submit"
                            loading={loading}
                            disabled={!canSubmit}
                        />
                    </div>
                </Grid>
            </Grid>
            <LoaderOverlay loading={isSubmitting} />
        </Form>
    );
};

const TransportType: React.FunctionComponent = () => {
    const {
        value,
        setValue,
    } = useField<TransportChoice[]>("transport", {
        defaultValue: [],
    });

    const handleToggleTransportChoices = (newVal: TransportChoice) => {
        const isIn = value.find(element => element === newVal);
        if(isIn){
            let transports = [...value];
            const index = transports.indexOf(newVal);
            transports.splice(index, 1);
            setValue(transports);
        }else{
            setValue([
                ...value,
                newVal
            ])
        }
    };

    
    //TODO add error?
    return (
        <div className="register-transport-modes">
            <MultipleTransportChoiceComponent
                values={value}
                toggleValue={handleToggleTransportChoices}
            />
        </div>
    );
};

export default RegisterForm;
