import {makeStyles} from "@material-ui/core/styles";
import {gql} from "@apollo/client";
import * as React from "react";
import {useSnackbar} from "notistack";
import {useMutation, useQuery} from "@apollo/client";
import {Locale} from "ias-lib";
import {useForm} from "react-form";
import Parameters from "parameters";
import {Grid} from "@material-ui/core";
import clsx from "clsx";
import CompanyField from "components/CompoundFields/CompanyField";
import PhoneField from "components/Fields/PhoneField";
import AddressField from "components/CompoundFields/AddressField";
import {TextField} from "shared/src/components/Fields";
import {LoaderOverlay} from "components";
import { ButtonBase } from "shared/src/components/Layout/Buttons/Button/Button";
import "./ClientCompany.scss";
import {useState} from "react";
import Confirm from "shared/src/components/Confirm";
import {useHistory} from "react-router-dom";


const ARCHIVE_CLIENT = gql`
    mutation archiveClient ($id: UUID!) {
        archiveClient (id: $id) {
            id
            reference
            createdAt
            status
            contact {
                id
                firstName
                lastName
                email
                phoneNumber
                role
            }
            status
            name
            taxNumber
            country
            address
            compAddress
            postalCode
            city
            country
            description
            numberId
            phone
            departureCountries
            arrivalCountries
            transportModes
            numberOperations
            knownBy
            products
        }
    }
`;

const useStyles = makeStyles(() => ({
    title: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    core: {
        padding: '25px',
    },
    item: {
        width: '100%',
        marginTop: '15px',
        marginBottom: '0px',
    },
    item_input: {
        backgroundColor: 'white',
        borderRadius: '5px',
    },
    button: {
        float: 'right',
    },
}));

const GET_COMPANY = gql`
query company($id: UUID!) {
    company(id: $id) {
        id
        name
        eori
        numberId
        address
        compAddress
        country
        postalCode
        city
        phone
        description
    }
}
`;
const UPDATE_COMPANY = gql`
mutation editCompany($id: UUID!, $input: CompanyInput!) {
    editCompany(id: $id, input: $input) {
        id
        name
        eori
        numberId
        address
        compAddress
        country
        postalCode
        city
        phone
        description
    }
}
`;

type PropsContent = {
    id: string
}

const ClientCompany: React.FunctionComponent<PropsContent> = ({id}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const {data, loading, error} = useQuery(GET_COMPANY, {
        variables:{id},
        onError: () => {
            enqueueSnackbar(Locale.trans('error.loading'), {variant: "error"});
        },
        fetchPolicy: "cache-and-network"
    });

    const history = useHistory();
    const [isArchiveConfirmOpen, setIsArchiveConfirmOpen] = useState<boolean>(false);
    const [archiveClient] = useMutation(ARCHIVE_CLIENT);
    const archive = () => {
        archiveClient({variables: {id}}).then(() => {
            enqueueSnackbar(Locale.trans('archive.success'), {variant: "success"});
            setIsArchiveConfirmOpen(false);
            history.push("/");

        }).catch(() => {
            enqueueSnackbar(Locale.trans('archive.error'), {variant: "error"});
        });
    };

    const [submit, {loading: submitting}] = useMutation(UPDATE_COMPANY);
    const {
        Form,
        meta: {isSubmitting, canSubmit}
    } = useForm({
        onSubmit: async (values: any) => {
            const {name, eori, numberId, address, compAddress, country, postalCode, city, phone, description} = values;
            const company = {
                name, eori, numberId, address, compAddress, country, postalCode, city, phone, description
            };
            submit({
                variables: {
                    id,
                    input: company,
                },
            }).then(() => {
                enqueueSnackbar(Locale.trans('profile.updated.company'), {variant: "success"});
            }).catch(() => {
                enqueueSnackbar(Locale.trans('error.undefined'), {variant: "error"});
            })
        },
        defaultValues: data && data.company,
        debugForm: Parameters.env === "dev",
    });
    if (loading) return null;
    if (error) return null;
    return (
        <Form className={"client-company"}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <span className="sub-title">
                        {Locale.trans('company.information')}
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <CompanyField/>
                </Grid>
                <Grid item xs={6}>
                    <PhoneField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)}
                                field="phone" required/>
                </Grid>
                <Grid item xs={12}>
                    <AddressField
                        hasCountry
                        country={"country"}
                        streetField={"address"}
                        compAddressField={"compAddress"}
                        postalCodeField={"postalCode"}
                        cityField={"city"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <span className="sub-title">
                        {Locale.trans('company.presentation')}
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className={clsx(classes.item)}
                        inputStyle={clsx(classes.item_input)}
                        field="description"
                        multiline
                        rows={5}
                        label={Locale.trans("company.description")}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className={"action-buttons"}>
                    <ButtonBase
                        variant={"text"}
                        color={"default"}
                        onClick={() => setIsArchiveConfirmOpen(true)}
                        label={Locale.trans('register.closeAccount')}
                    />
                    <ButtonBase
                        variant={"contained"}
                        color={"primary"}
                        type={"submit"}
                        disabled={!canSubmit}
                        label={Locale.trans('register.save')}
                    />
                </Grid>
            </Grid>
            <LoaderOverlay loading={isSubmitting || submitting || loading} />
            <Confirm
                open={isArchiveConfirmOpen}
                onClose={() => setIsArchiveConfirmOpen(false)}
                title={Locale.trans("archive.confirmTitle")}
                content={`${Locale.trans('archive.confirmContent')}`}
                onAccept={archive}
                onRefuse={() => setIsArchiveConfirmOpen(false)}
            />
        </Form>
    );
};

export default ClientCompany;