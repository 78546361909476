import * as React from 'react';
import {Locale} from "ias-lib";
import CountrySelect from "shared/src/components/Country/CountrySelect";
import MultipleCountrySelect from "shared/src/components/Country/MultipleCountrySelect";

type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field: string;
    required?: boolean;
    label?: string;
    countryValue?: string;
    setCountryValue?: (y: string) => void;
    disabled?: boolean;
    suggestedCountries?: string[];
    variant?: "standard" | "outlined";
    multiple?: boolean;
}

const CountryField: React.FunctionComponent<FieldsProps> = ({
                                                                countryValue,
                                                                setCountryValue,
                                                                className,
                                                                inputStyle,
                                                                field,
                                                                required,
                                                                label,
                                                                disabled,
                                                                suggestedCountries,
                                                                variant,
                                                                multiple
                                                            }) => {

    if(multiple){
        return(
            <MultipleCountrySelect
                className={className}
                id={field||"country"}
                label={label || Locale.trans('country')}
                required={required}
                selectProps={{
                    className: inputStyle
                }}
                disabled={disabled}
                suggestedCountries={suggestedCountries}
                variant={variant ? variant : "outlined"}
            />
        )
    }
    return (
        <CountrySelect
            className={className}
            id={field||"country"}
            label={label || Locale.trans('country')}
            required={required}
            selectProps={{
                className: inputStyle
            }}
            countryValue={countryValue}
            setCountryValue={setCountryValue}
            disabled={disabled}
            suggestedCountries={suggestedCountries}
            variant={variant ? variant : "outlined"}
        />
    )
};
export default CountryField;
