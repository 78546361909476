import * as React from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import clsx from "clsx";
import logo from "images/logo couleur.png";
import {Locale} from "ias-lib";
import image from "images/urn_aaid_sc_US_e462e56d-a471-434e-a46e-3bb3d3d19841.png";
import {useEffect, useState} from "react";
import Parameters from 'parameters';
import {useSnackbar} from "notistack";
import ForgottenPasswordRequestForm from "./ForgottenPasswordRequestForm";
import {useLocation, useHistory} from 'react-router-dom';

type Props = {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#FFFFFF',
            height: '100vh'
        },
        form_part: {
            height: '100%',
            boxShadow: '2px 0 2px 1px white',
            zIndex: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        form: {
            height: '50%',
            width: '50%',
            textAlign: 'left',
        },
        image_part: {
            maxHeight: '100vh',
            overflow: 'hidden',
        },
        title: {
            marginTop: '15px',
            fontSize: '2.75rem',
        },
    })
);
const ForgottenPasswordRequest: React.FunctionComponent<Props> = () => {
    const location = useLocation();
    const classes= useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [validationCode, setValidation] = useState("");
    const history = useHistory();

    useEffect(() => {
        const searchParts = location.search.slice(1).split("&");
        searchParts.forEach((part) => {
            const parts = part.split("=");
            if(parts[0] === "email") {
                setEmail(parts[1])
            }if(parts[0] === "validation-code") {
                setValidation(parts[1])
            }
        })
    }, [location]);

    return (

        <div>
            <Grid container className={clsx(classes.root)}>
                <Grid item className={clsx(classes.form_part)} xs={6}>
                    <div className={clsx(classes.form)}>
                        <img width="50%" src={logo} alt="Logo" />
                        <Typography variant="h2" gutterBottom className={clsx(classes.title)}>
                            {Locale.trans('login.new_password_title')}
                        </Typography>
                        <ForgottenPasswordRequestForm
                            onSubmit={async (plainPassword: string ) => {
                                try {
                                    setLoading(true);
                                    const res = await fetch(`${
                                            Parameters.ApiUrl
                                        }change-password`,
                                        {
                                            method: 'POST',
                                            headers: {
                                                Accept: 'application/json',
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify({
                                                email,
                                                plainPassword,
                                                validationCode,
                                            })
                                        },
                                    );
                                    if(res.ok) {
                                        enqueueSnackbar(Locale.trans('login.password_updated'), {variant: 'success'});
                                        history.push('/login');
                                    } else {
                                        enqueueSnackbar(Locale.trans('login.request_error'), {variant: 'error'});
                                    }
                                    setLoading(false);
                                } catch(error)  {
                                    console.error(error);
                                    setLoading(false);
                                    enqueueSnackbar(Locale.trans('error.undefined'), {variant: 'error'});
                                }
                            }}
                            loading={loading}
                        />
                    </div>
                </Grid>
                <Grid item className={clsx(classes.image_part)} xs={6}>
                    <div className="logo">
                        <img src={image} alt="Logo" height="100%" width={"100%"} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
export default ForgottenPasswordRequest;
