import * as React from 'react';
import NameField from "../Fields/NameField";
import clsx from "clsx";
import {createStyles, Grid, Theme} from "@material-ui/core";
import {TextField} from "shared/src/components/Fields";
import {Locale} from "ias-lib";
import {makeStyles} from "@material-ui/core/styles";

type Props = {
    disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '25px',
        },
        item: {
            width: '100%',
            marginTop: '15px',
            marginBottom: '0px',
        },
        item_input: {
            backgroundColor: 'white',
            borderRadius: '5px',
        },
        button_line: {
            width: '100%',
            padding: '16px',
        },
        button: {
            float: 'right',
        },
        button_loading: {},
        marginTop: {
            marginTop: 15,
        },
    })
);
const CompanyField: React.FunctionComponent<Props> = ({disabled}) => {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <NameField
                        className={clsx(classes.item)}
                        inputStyle={clsx(classes.item_input)}
                        field="name"
                        type="company"
                        disabled={disabled}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        inputStyle={clsx(classes.item_input)}
                        className={clsx(classes.item)}
                        field="numberId"
                        label={Locale.trans('company.numId')}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        inputStyle={clsx(classes.item_input)}
                        className={clsx(classes.item)}
                        field="eori"
                        label={Locale.trans('company.eori')}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        </>
    );
}
export default CompanyField;
