import * as React from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {ReactNode} from "react";

type Props = {
    className?: string;
    field?: string;
    required?: boolean;
    label?: string|React.ReactElement
    onChange: (key: React.ChangeEvent<Element>) => void;
    disabled?: boolean;
    value: boolean;
    icon?: ReactNode;
    checkedIcon?: ReactNode
}

export const CheckBoxElement: React.FunctionComponent<Props> = ({ className,
                                                             field,
                                                             required,
                                                             label,
                                                             onChange,
                                                             disabled,
                                                             value,
                                                             icon,
                                                             checkedIcon}) => {
    return (
        <FormControlLabel
            className={className}
            control={
                <Checkbox
                    checked={value}
                    onChange={onChange}
                    name={field}
                    required={required}
                    color="primary"
                    disabled={disabled}
                    disableRipple
                    icon={icon}
                    checkedIcon={checkedIcon}
                />}
            label={label}
        />
    )
}
export default CheckBoxElement;