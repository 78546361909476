import * as React from 'react';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlightIcon from '@material-ui/icons/Flight';

type Props = {
    type: "" | "boat" | "train" | "plane";
    color?: "primary"|"secondary"|"action"|"disabled"
    className?: string;
    styleColor?: string;
}

const IconTransportType: React.FunctionComponent<Props> = ({type, className, color,styleColor}) => {
    switch(type) {
        case 'boat':
            return <DirectionsBoatIcon style={{verticalAlign:'text-bottom', color: styleColor ||'black'}} className={className} color={color}/>;
        case 'plane':
            return <FlightIcon style={{verticalAlign:'text-bottom', color: styleColor ||'black', transform: 'Rotate(90deg)'}} className={className} color={color}/>;
        case 'train':
            return <DirectionsRailwayIcon style={{verticalAlign:'text-bottom', color: styleColor ||'black'}} className={className} color={color}/>;
        default:
            return null
    }
}
export default IconTransportType;
