import React, {ChangeEvent, ReactNode, useEffect, useState} from 'react';
import {Locale} from "ias-lib";
import {CircularProgress, TextField,} from "@material-ui/core";
import {Autocomplete, AutocompleteProps, UseAutocompleteProps} from "@material-ui/lab";
import styled from 'styled-components';
import {useField} from "react-form";
import {useQuery} from "@apollo/client";
import {GET_COUNTRIES} from "./gql";
import removeDiacritics from "shared/src/services/removeDiacritics";
import {Logger} from "../../services/Logger";
import './CountrySelect.scss';

type Props = {
    label: string;
    id: string,
    onChange?: (e: ChangeEvent<{}>, child: ReactNode) => void;
    value?: string,
    error?: string | null,
    className?: string;
    required?: boolean;
    disabled?: boolean;
    selectProps?: any; //TODO
    data: Country[];
    countryValue?: string;
    selectedCountries?: string[];
    setCountryValue?: (v: string) => void;
    loading: boolean;
    clearOnSelect?: boolean;
    suggestedCountries?: string[];
    variant?: "standard" | 'outlined';
}

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    background-color: transparent;
    color: #0000008A;
  }

  .MuiInputLabel-animated {
    z-index: 999;
  }
` as React.ComponentType<AutocompleteProps<string, false, false, false> & UseAutocompleteProps<string, false, false, false>>;


function validateCountry(countryValue: string, value: string) {
    if (!countryValue && !value) {
        return Locale.trans('error.field_required');
    }
    return false;
}

// from https://bitbucket.org/atlassian/design-system-mirror/src/master/design-system/select/src/data/countries.ts
interface CountryType {
    abbr: string;
    icon: string;
    name: string;
    suggested?: boolean;
}

function CountrySelect({
                           loading,
                           label,
                           id,
                           className,
                           required,
                           disabled,
                           selectProps,
                           data,
                           countryValue,
                           clearOnSelect,
                           selectedCountries,
                           setCountryValue,
                           suggestedCountries,
                           variant,
                       }: Props) {
    const [countryDefinitions, setCountryDefinitions] = useState<CountryType[]>([]);
    const [countries, setCountries] = useState<string[]>([]);


    useEffect(() => {

        const correctCountries = data.filter((country: Country) => country.iso !== '-' && country.fips !== '-' && country.iso !== '' && country.fips !== '');
        setCountryDefinitions(correctCountries.map((country: Country) => {
            return {
                icon: country.iso,
                name: Locale.trans(`countries.${country.iso}`),
                abbr: country.iso,
                suggested: country.suggested,
            }
        }));
        setCountries(
            correctCountries.map((country: Country) => {
                return country.iso;
            }))
    }, [data]);


    const {
        meta: {error},
        getInputProps,
        value: fieldValue
    } = useField(id, {
        //validate: validateCountry, TODO
        defaultValue: '',
    });
    const {onChange} = getInputProps();

    const [value, setValue] = useState<string>(countryValue || fieldValue);
    const [inputValue, setInputValue] = useState<string>("");

    useEffect(() => {
        if (value !== fieldValue) {
            setValue(fieldValue);
        }
        // eslint-disable-next-line
    }, [fieldValue]);

    const handleTest = (e: any, value: any) => {
        setValue(value);
        if (setCountryValue !== undefined) {
            setCountryValue(value);
        }
        onChange({target: {value}} as unknown as ChangeEvent);
    };

    let options = countries;
    let countryDefinitionsWrapper = countryDefinitions;
    if (countries.length === 0 && value) {
        options = [value];
        countryDefinitionsWrapper = [
            {
                icon: value,
                name: Locale.trans(`countries.${value}`),
                abbr: value,
                suggested: false,
            },
            ...countryDefinitions
        ]
    }

    return (
        <StyledAutocomplete
            disabled={disabled}
            multiple={false}
            id={id}
            options={options}
            inputValue={inputValue}
            onInputChange={(event, newValue) => setInputValue(newValue)}
            value={(value === "" ? null : value)}
            onChange={handleTest}
            autoHighlight
            groupBy={(country: string) => {
                const option = countryDefinitionsWrapper.find(item => item.abbr === country.toUpperCase());
                return ((option && option.suggested) || (suggestedCountries && suggestedCountries.includes(country))) ? Locale.trans('countries.suggested') : Locale.trans('countries.all')
            }}
            getOptionLabel={(country: string) => {
                const option = countryDefinitionsWrapper.find(item => item.abbr === country.toUpperCase());
                return (option && option.name) || "";
            }}
            renderOption={(country: string) => {
                const option = countryDefinitionsWrapper.find(item => item.abbr === country.toUpperCase());
                return (
                    <React.Fragment>
                        {Locale.trans(`countries.${option && option.abbr}`)} ({option && option.icon})
                    </React.Fragment>
                )
            }}
            filterOptions={(options: string[], state: { inputValue: string | null }) => options.filter((country: string) => {
                const option = countryDefinitionsWrapper.find(item => item.abbr === country.toUpperCase());
                return (option && removeDiacritics(option.name.toLowerCase()).includes((removeDiacritics(state.inputValue) || '').toLowerCase()));
            })}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={!!error}
                    helperText={error}
                    required={required}
                    disabled={disabled}
                    className={`${className} country-select-style`}
                    onClick={() => {
                        if (clearOnSelect) {
                            setInputValue("");
                            setValue("")
                        }
                    }}
                    label={(
                        <>
                            {loading && <CircularProgress size={16}/>}
                            {label}
                        </>)}
                    variant={variant ? variant : "outlined"}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'chrome-off', // disable autocomplete and autofill
                        // country-select-style rule needs to be set in input props for font-family to be applied to value
                        className: 'country-select-style',
                        ...selectProps,
                    }}
                />
            )}
        />
    );

}

type WrapperProps = {
    label: string;
    id: string,
    onChange?: (e: ChangeEvent<{}>, child: ReactNode) => void;
    value?: string,
    error?: string | null,
    className?: string;
    required?: boolean;
    selectProps?: any; //TODO
    countryValue?: string;
    selectedCountries?: string[];
    setCountryValue?: (v: string) => void;
    clearOnSelect?: boolean;
    disabled?: boolean;
    suggestedCountries?: string[];
    variant?: "standard" | "outlined";
}
const EU_COUNTRIES = ["BE", "EL", "LT", "PT", "BG", "ES", "LU", "RO", "CZ", "FR", "HU", "SI", "DK", "HR", "MT", "SK", "DE", "IT", "NL", "FI", "EE", "CY", "AT", "SE", "IE", "LV", "PL"];
const Wrapper: React.FunctionComponent<WrapperProps> = ({
                                                            label,
                                                            disabled,
                                                            id,
                                                            className,
                                                            required,
                                                            selectProps,
                                                            countryValue,
                                                            setCountryValue,
                                                            selectedCountries,
                                                            clearOnSelect,
                                                            suggestedCountries,
                                                            variant,
                                                        }) => {
    const {data, error, loading} = useQuery(GET_COUNTRIES, {
        fetchPolicy: "cache-and-network"
    });
    if (error) {
        Logger.logError(error);
    }
    const countriesArray = [...(data?.countries || [])]
        .sort((a: Country, b: Country) => {
            if ((suggestedCountries || []).find(c => c === a.iso) && !(suggestedCountries || []).find(c => c === b.iso)) {
                return -1;
            }
            if (!(suggestedCountries || []).find(c => c === a.iso) && (suggestedCountries || []).find(c => c === b.iso)) {
                return 1;
            }

            if (a.iso === "FR") {
                return -1;
            }
            if (b.iso === "FR") {
                return 1;
            }
            if (EU_COUNTRIES.find(c => c === a.iso) && EU_COUNTRIES.find(c => c === b.iso)) {
                if (Locale.trans(`countries.${a.iso}`) < Locale.trans(`countries.${b.iso}`)) {
                    return -1;
                }
                if (Locale.trans(`countries.${a.iso}`) > Locale.trans(`countries.${b.iso}`)) {
                    return 1;
                }
                return 0;
            }
            if (EU_COUNTRIES.find(c => c === a.iso)) {
                return -1;
            }
            if (EU_COUNTRIES.find(c => c === b.iso)) {
                return 1;
            }
            if (Locale.trans(`countries.${a.iso}`) < Locale.trans(`countries.${b.iso}`)) {
                return -1;
            }
            if (Locale.trans(`countries.${a.iso}`) > Locale.trans(`countries.${b.iso}`)) {
                return 1;
            }
            return 0;
        });

    return (
        <CountrySelect
            disabled={disabled}
            label={label}
            id={id}
            className={className}
            required={required}
            selectedCountries={selectedCountries}
            selectProps={selectProps}
            data={countriesArray}
            countryValue={countryValue}
            setCountryValue={setCountryValue}
            loading={loading}
            clearOnSelect={clearOnSelect}
            suggestedCountries={suggestedCountries}
            variant={variant ? variant : "outlined"}
        />
    )
};

export default Wrapper;
