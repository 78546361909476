import * as React from 'react';
import {useField} from "react-form";
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useEffect, useState} from "react";
import {Locale} from "ias-lib";


type Choice = {
    value: string;
    name: any;
}

type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field?: string;
    required?: boolean;
    disabled?: boolean;
    label: string;
    options: any[];
    onChange?: (e: any, newValue: Choice) => void;
    onClear?: () => void;
    value?: Choice | null;
    textFieldValue?: string;
    setValue?: (arg: Choice | null) => void;
    multiple?: boolean;
    variant?: "standard" | "outlined";
    loading?: boolean;
}

const useStyles = makeStyles({
    label: {
        "& .MuiFormLabel-root": {
            fontFamily: 'Lato, sans-serif',
        },
    }
})


export const AutocompleteField: React.FunctionComponent<FieldsProps> = ({
                                                                            multiple,
                                                                            textFieldValue,
                                                                            setValue,
                                                                            onClear,
                                                                            value,
                                                                            onChange,
                                                                            options,
                                                                            label,
                                                                            className,
                                                                            inputStyle,
                                                                            required,
                                                                            field,
                                                                            disabled,
                                                                            variant,
                                                                            loading
                                                                        }) => {

    const classes = useStyles();
    field = field || "autocomplete";
    const {
        meta: {error},
        getInputProps,
    } = useField(field, {
        defaultValue: "",
    });

    const [inputValue, setInputValue] = useState<string>();

    const {onChange: onChangeField} = getInputProps();
    const handleOnChange = (event: any, value: Choice, reason: any) => {
        if (reason === 'clear') {
            if (onClear) {
                onClear();
            }
        } else {
            if (onChange) {
                onChange(event, value);
                //onChangeField({target:{val: value.id}} as unknown as React.ChangeEvent)
            }
        }
    }


    return (

        <Autocomplete
            className={clsx(className, classes.label)}
            id={field}
            options={options}
            disabled={disabled}
            inputValue={inputValue}
            onInputChange={(event, newValue) => setInputValue(newValue)}
            defaultValue={value ? options.find(option => option.value === value) : ""}
            getOptionLabel={(option: any) => option.name || ""}
            renderOption={option => <div
                style={{fontFamily: 'Lato, sans-serif', fontSize: "16px", fontWeight: 'normal'}}>{option.name}</div>}
            onChange={handleOnChange}
            value={value}
            autoHighlight
            noOptionsText={<div
                style={{fontFamily: 'Lato, sans-serif', fontSize: "16px", fontWeight: 'normal'}}>{loading ? Locale.trans("loadingField") : Locale.trans("noResults")}</div>}
            openOnFocus={true}
            getOptionSelected={(option, v) => {
                return option.value === v.value
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    {...getInputProps()}
                    InputProps={{
                        ...params.InputProps,
                        classes: {
                            input: inputStyle,

                        },
                        style: {fontFamily: "Lato, sans-serif"},
                    }}
                    {...getInputProps()}

                    helperText={error}
                    required={required}
                    error={(!!error)}
                    label={label}
                    variant={variant ? variant : "outlined"}
                />}
        />
    )
};
export default AutocompleteField;
