export function cleanEntry(obj: any) : any {
    return (function recurse(obj, current) {
        let res: any = Array.isArray(obj) ? [] : {};
        for (let key in obj) {
            if(key==="__typename" || key === "id") {
                continue;
            }
            let value = obj[key];
            if (value && typeof value === 'object') {
                // @ts-ignore
                res[key] = recurse(value, key);
            } else {
                if (value !== undefined && value !== 'undefined' && value !== null) {
                    res[key] = value;
                }
            }
        }
        return res;
    })(obj);

}

export function objectToDot(obj: any) : Record<string, string> {
    let res: any = {};
    (function recurse(obj, current) {
        for (let key in obj) {
            if(key==="__typename" || key === "id") {
                continue;
            }
            let value = obj[key];
            let newKey = '';
            if(Array.isArray(obj)){
                newKey = (current ? current + '[' + key + ']' : key);
            } else {
                newKey = (current ? current + '.' + key : key);
            }
            if (value && typeof value === 'object') {
                // @ts-ignore
                recurse(value, newKey);
            } else {
                if (value !== undefined && value !== 'undefined' && value !== null) {
                    res[newKey] = value;
                }
            }
        }
    })(obj);
    return res;
}
export function dotToObject(obj: any) {
        for (let key in obj) {
        if (key.indexOf('.') !== -1) {
            parseDotNotation(key, obj[key], obj);
            delete obj[key];
            continue;
        }
        if (obj[key] === 'undefined' || obj[key] === undefined || obj[key] === 'null' || obj[key] === null) {
            obj[key] = '';
        }
    }
    return obj;

}

function parseDotNotation(str: string, val: any, obj: any) {
    let currentObj = obj,
        keys = str.split('.'),
        i, l = Math.max(1, keys.length - 1),
        key;

    for (i = 0; i < l; ++i) {
        key = keys[i];
        if(!isNaN(parseInt(key)) &&  Object.keys(currentObj).length === 0) {
            currentObj = [];
        }
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
    }

    if (val !== 'undefined' && val !== undefined && val !== null && val !== 'null') {
        currentObj[keys[i]] = val;
    }
    //delete obj[str];
}