import * as React from "react";
import {ChangeEvent, ReactNode, useState} from "react";
import styled from "styled-components";
import {Autocomplete, AutocompleteProps, UseAutocompleteProps} from "@material-ui/lab";
import {Locale} from "ias-lib";
import {useField} from "react-form";
import {TextField} from "@material-ui/core";
import './IncotermSelect.scss';

type Props = {
    label: string;
    id: string,
    onChange?: (e: ChangeEvent<{}>, child: ReactNode) => void;
    value?: string,
    error?: string | null,
    className?: string;
    required?: boolean;
    selectProps?: any; //TODO
    setIncotermValue?: (v: string) => void;
    disabled?: boolean;
}

const StyledAutocomplete = styled(Autocomplete)`
    .MuiInputBase-root {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        background-color: white;
    }
`  as React.ComponentType<AutocompleteProps<string, false, false, false> & UseAutocompleteProps<string, false, false, false>>;



const IncotermSelect: React.FunctionComponent<Props> = ({disabled, label, id, className, required, selectProps, setIncotermValue, value: defaultValue}) => {

    function validateIncoterm(value: string) {
        if (!value && required) {
            return Locale.trans('error.field_required');
        }
        return false;
    }

    const [incoterms] = useState<string[]>([
        "EXW",
        "FCA",
        "FAS",
        "FOB",
        "CFR",
        "CIF",
        "CPT",
        "DAP",
        "DAT",
        "DDP",
    ]);

    const {
        meta: {error},
        getInputProps
    } = useField(id, {
        validate: validateIncoterm,
        defaultValue: defaultValue,
    });

    const {value, onChange} = getInputProps();

    const handleTest = (e:any, value: any) => {
        if(setIncotermValue !== undefined){
            setIncotermValue(value);
        }
        onChange({target: {value}} as unknown as ChangeEvent<Element>);//TODO
    };

    return (
        <StyledAutocomplete
            // classes={{
            //     root: classes.root
            // }}
            multiple={false}
            id={id}
            options={incoterms}
            value={value}
            onChange={handleTest}
            disabled={disabled}
            autoHighlight
            groupBy={(incoterm: string) => {
                return Locale.trans('countries.all')
            }}
            getOptionLabel={(incoterm: string) => {
                const option = Locale.trans(`incoterms.${incoterm}`);
                return (option !== "incoterms." && option) || "";
            }}
            renderOption={(incoterm: string) => {
                return (
                    <div className="incoterm-selection-style">
                        {Locale.trans(`incoterms.${incoterm}`)}
                    </div>
                )}}
            filterOptions={(options: string[], state: {inputValue: string|null}) => options.filter((incoterm: string) => {
                const option = Locale.trans(`incoterms.${incoterm}`);
                return (option);
            }).slice(0, 10)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={!!error}
                    helperText={error}
                    required={required}
                    className={className}
                    label={label}
                    disabled={disabled}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                        className: '',
                        ...selectProps,
                    }}
                />
            )}
        />
    );
};

export default IncotermSelect;