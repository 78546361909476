import * as React from 'react';
import {useField} from "react-form";
import {FormControlLabel, Checkbox} from "@material-ui/core";
import {ReactNode} from "react";
import CheckBoxElement from "./CheckBoxElement";

type FieldsProps = {
    className?: string;
    field: string;
    required?: boolean;
    label?: string
    onChange?: (key: boolean) => void;
    disabled?: boolean;
    defaultValue?: boolean;
    icon?: ReactNode;
    checkedIcon?: ReactNode
}

export const CheckBoxField: React.FunctionComponent<FieldsProps>  = ({icon, checkedIcon, defaultValue, disabled, className, field, required, label, onChange: onChangeValue}) => {
    const {
        getInputProps
    } = useField(field, {
        defaultValue: defaultValue || false,
    });
    const {value, onChange} = getInputProps();

    const handleChange = (event: any) => {
        onChange( {
            target: {
                // @ts-ignore
                value: event.target.checked,
            }
        });
        if(onChangeValue){
            onChangeValue(event.target.checked);
        }
    };

    return (
        <CheckBoxElement
            className={className}
            field={field}
            required={required}
            label={label}
            onChange={handleChange}
            disabled={disabled}
            value={value}
            icon={icon}
            checkedIcon={checkedIcon}
        />
    )
};

export default CheckBoxField;
