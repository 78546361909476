import * as React from 'react';
import {useField} from "react-form";
import {CircularProgress, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ChangeEvent, useEffect, useState} from "react";
import removeDiacritics from "../../services/removeDiacritics";
import {Locale} from "ias-lib";


type Choice = {
    value: string;
    name: any;
}

type Option = {
    value: any;
    name: string;
    category?: string;
}

type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field?: string;
    required?: boolean;
    disabled?: boolean;
    label: string;
    loading?: boolean;
    selectProps?: any;
    options: Option[];
    variant?: "standard" | "outlined";
}
const useStyles = makeStyles({
    label: {
        "& .MuiFormLabel-root": {
            fontFamily: 'Lato, sans-serif',
        },
    }
})




export const MultipleAutocompleteField: React.FunctionComponent<FieldsProps>  = ({
    options,
    label,
    className,
    inputStyle,
    required,
    field,
    disabled,
    variant,
    loading,
    selectProps
}) => {

    const classes = useStyles();
    field = field || "autocomplete";
    const {
        meta: {error},
        value: fValue,
        getInputProps,
    } = useField(field, {
        defaultValue: [],
    });

    return (

        <Autocomplete
            multiple
            limitTags={8}
            {...getInputProps()}
            onChange={(e,value: Option[]) => {
                getInputProps().onChange({
                    target: {
                        value: value.map((v: string|Option) => {
                            if (typeof v === "string") {
                                return v;
                            }
                            return v.value;
                        })
                    },
                } as unknown as ChangeEvent)
            }}
            className={clsx(className, classes.label)}
            id={field}
            options={options}
            disabled={disabled}
            groupBy={(option: Option) => {
                return option.category ? Locale.trans(`groupBy.${option.category}`) : Locale.trans('groupBy.all');
            }}
            getOptionLabel={(option: Option) => {
                if(option.name === undefined) {
                    // @ts-ignore
                    option = options.find(o => o.value === option);
                }
                return option?.name || ""
            }}
            autoHighlight
            openOnFocus={true}
            getOptionSelected={(option: Option, v) => {
                return option.value === v;
            }}
            filterOptions={(options: Option[], state: { inputValue: string|null }) => options.filter((option: Option) => {
                return (option && removeDiacritics(option.name.toLowerCase()).includes((removeDiacritics(state.inputValue) || '').toLowerCase()));
            })}
            renderInput={(params) =>
                <TextField
                    InputProps={{
                        className: inputStyle,
                        style: {fontFamily: "Lato, sans-serif"},
                    }}
                    {...params}
                    className={className}
                    helperText={error}
                    required={required ? fValue.length === 0 : false }
                    error={(!!error)}
                    disabled={disabled}
                    label={(
                    <>
                        {loading && <CircularProgress size={16}/>}
                        {label}
                    </>)}
                    variant={variant ? variant : "outlined"}
                />}
        />
    )
};
export default MultipleAutocompleteField;
