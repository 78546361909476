import * as React from "react";
import {InputAdornment, TextField} from "@material-ui/core";
import {Locale} from "ias-lib";
import SearchIcon from "@material-ui/icons/Search";
import {useEffect, useState} from "react";
import moment from "moment";
import './SearchField.scss';

type Props = {
    objects: any; //Need to be the props one not the state one, to have the base that doesn't change
    setObjects: (arg: any[]) => void;
    toCheck: string[];
    admin?: boolean;
}

export const SearchField: React.FunctionComponent<Props> = ({objects, setObjects, toCheck, admin}) => {

    const [valueSearch, setValueSearch] = useState<string>("");

    const check = (key: string, object: any): any => {
        let toReturn = false;
        var fullName1;
        var fullName2;
        if(key.includes(".")){
            const k = key.substr(0, key.indexOf("."));
            const subKey = key.substr(key.indexOf(".")+1);
            if (subKey.includes("start.date")) {
                toReturn = moment(object[k].start.date).format('DD/MM/Y').includes(valueSearch);
            }
            else if (subKey.includes("end.date")) {
                toReturn = moment(object[k].end.date).format('DD/MM/Y').includes(valueSearch);
            }
            else {
                toReturn = check(subKey, object[k]);
            }
        }else{
            if(!object) return false;
            if(object[key] !== undefined){
                if(object[key] === null){
                    return false;
                }
                if(key === "country"){
                    if(Locale.trans(`countries.${object[key]}`).toLowerCase().includes(valueSearch.toLowerCase())){
                        return true;
                    }
                }
                else if(typeof object[key] === "string"){
                    if ((object[key].toLowerCase()).includes(valueSearch.toLowerCase())) {
                        return true;
                    }
                }
            }
            else if(typeof object === "object") {
                if(object.length){//Check if array and not just object
                    object.forEach((object: any) => toReturn = toReturn || check(key, object));
                }
                else if(key === "fullName") {
                    fullName1 = object["firstName"].concat(' ', object["lastName"]);
                    fullName2 = object["lastName"].concat(' ', object["firstName"]);
                    return fullName1.toLowerCase().includes(valueSearch.toLowerCase()) || fullName2.toLowerCase().includes(valueSearch.toLowerCase());
                }
            } else if(key === "fullName"){
                fullName1 = object["firstName"].concat(' ', object["lastName"]);
                fullName2 = object["lastName"].concat(' ', object["firstName"]);
                return fullName1.toLowerCase().includes(valueSearch.toLowerCase()) || fullName2.toLowerCase().includes(valueSearch.toLowerCase());
            }

        }
        return toReturn;
    };


    useEffect(() => {
        if(valueSearch === ""){
            setObjects(objects);
        }else{
            const tmp = objects.filter((object: any) => {
                let toKeep = false;
                toCheck.forEach((value: string) => {
                    if(check(value, object)){
                        toKeep = true;
                    }
                })
                if(toCheck.length === 0) return  true;
                return toKeep;
            })
            setObjects(tmp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueSearch])


    const handleChange = (e:any) => {
        setValueSearch(e.target.value);
    }

    return (
        <>
            <TextField
                className={`search-field ${admin ? 'admin' : ''}`}
                label={Locale.trans('search')}
                type="search"
                variant="outlined"
                InputLabelProps={{
                    className: 'input-style'
                }}
                InputProps={{
                    className: 'search-field-style',
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon style={{color:"#9fa5ab"}}/>
                        </InputAdornment>
                    )
                }}
                value={valueSearch}
                onChange={handleChange}
            />
        </>
    );
};

export default SearchField;