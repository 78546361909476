
import useCurrentUser from "shared/src/hooks/useCurrentUser";
import {useSnackbar} from "notistack";
import {Locale} from "ias-lib";
import {useEffect} from "react";
import Parameters from 'parameters';


function useIsGranted(debug = false) {
    const {currentUser, loading, error} = useCurrentUser();
    const {enqueueSnackbar} = useSnackbar();

    let isGranted = (role: string) => {
        if(!error && !loading && currentUser !== null) {
            if(currentUser.roles.indexOf(role) !== -1) {
                return true;
            }
            if(Parameters.env === 'dev' && debug) {
                console.debug(`Role not found: ${role} in ${currentUser.roles}`);
            }
        }
        return false;
    };

    useEffect(() => {
        if(error) {
            enqueueSnackbar(Locale.trans("error.current_user"), {variant: "error"});
        }
    }, [error, enqueueSnackbar]);

    useEffect(() => {
        // @ts-ignore
        const $crispVar = $crisp;
        if($crispVar && currentUser) {
            $crispVar.push(["set", "user:company", [currentUser.company.name]]);
            $crispVar.push(["set", "user:nickname", [currentUser.firstName + " " + currentUser.lastName]]);
            $crispVar.push(["set", "user:email", [currentUser.email]]);
            $crispVar.push(["set", "user:phone", [currentUser.phoneNumber]]);
        }
    }, [currentUser])

    return {
        loading,
        isGranted,
        currentUser
    }
}

const CLIENT = 'COMPANY_CLIENT';
const PARTNER = 'COMPANY_PARTNER';
const ADMIN = 'COMPANY_ADMIN';
const ADMIN_EMPLOYEE = 'manager';

export {
    useIsGranted,
    CLIENT,
    PARTNER,
    ADMIN,
    ADMIN_EMPLOYEE,
}
