import * as React from "react";
import CitySelect from "shared/src/components/City/CitySelect";

type FieldsProps = {
    className?: string;
    inputStyle?: string;
    field: string;
    required?: boolean;
    label?: string;
    countryIso?: string;
    useUnlocode?: boolean;
    setSelectedCity?: (value: any) => void;
    disabled?: boolean;
    baseValue?: City;
    isPort?: boolean;
    selectedCity?: City | null;
    minTextLength?: number;
    variant?: "standard"|"outlined";
}

const CityField: React.FunctionComponent<FieldsProps> = ({
                                                             isPort,
                                                             minTextLength,
                                                             selectedCity,
                                                             baseValue,
                                                             disabled,
                                                             setSelectedCity,
                                                             className,
                                                             inputStyle,
                                                             field,
                                                             required,
                                                             label,
                                                             countryIso,
                                                             useUnlocode,
                                                             variant,
                                                         }) => {
    return (
        <CitySelect
            className={className}
            field={field}
            label={label}
            required={required}
            inputStyle={inputStyle}
            countryIso={countryIso}
            useUnlocode={useUnlocode}
            setSelectedCity={setSelectedCity}
            disabled={disabled}
            baseValue={baseValue}
            isPort={isPort}
            selectedCity={selectedCity}
            minTextLength={minTextLength}
            variant={variant ? variant : "outlined"}
        />
    );
};

export default CityField;