import {gql} from "@apollo/client";


const GET_NOTIFICATION_CONFIGURATION = gql`
query notificationConfiguration{
    notificationConfiguration {
        id
        availableQuotation
        availableOffer
        declinedOffer
        cancellation
        bookingToValidate
        BLToValidate
        scheduledTransport
        newMessage
        billToPay
        newCorrespondingQuotationRequest
        offerAccepted
        emailNotification
        accessToExpeditionUnlocked
    }
}
`;

const EDIT_NOTIFICATION_CONFIGURATION = gql`
mutation editNotificationConfiguration ($input: NotificationConfigurationInput!){
    editNotificationConfiguration (input: $input){
        id
        availableQuotation
        availableOffer
        declinedOffer
        cancellation
        bookingToValidate
        BLToValidate
        scheduledTransport
        newMessage
        billToPay
        newCorrespondingQuotationRequest
        offerAccepted
        emailNotification
        accessToExpeditionUnlocked
    }
}
`;

export {
    GET_NOTIFICATION_CONFIGURATION,
    EDIT_NOTIFICATION_CONFIGURATION,
}