import * as React from 'react';
import {Button, CircularProgress, Grid} from "@material-ui/core";
import UsernameField from "shared/src/components/Fields/UsernameField/UsernameField";
import clsx from "clsx";
import {Locale} from "ias-lib";
import ActionButton from "components/MaterialUi/ActionButton";
import {useForm} from "react-form";
import Parameters from "parameters";
import {useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        item: {
            width: '100%',
            marginTop: '15px',
            marginBottom: '15px',
        },
        button: {},
        button_loading: {},
    })
);

const ForgottenButton = withStyles((theme: Theme) => ({
    root: {
        color: '#00B796',
        paddingLeft: 0,
        padding: '14px',
        fontSize: '14px',
        fontFamily: "Lato, sans-serif",
        fontWeight: 'normal',
        textTransform: 'none'
    },
}))(Button);


type Props = {
    onSubmit: (mail: string) => void;
    loading: boolean;
}

const ResetPasswordRequestForm: React.FunctionComponent<Props> = ({onSubmit, loading}) => {
    const {
        Form,
        meta: {isSubmitting, canSubmit}
    } = useForm({
        onSubmit: async (values: any, instance: any) => {
            await onSubmit(values.username as string);
        },
        debugForm: Parameters.env === "dev",
    });
    const classes = useStyles();
    const history = useHistory();
    return (
        <Form>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <UsernameField className={clsx(classes.item)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ForgottenButton onClick={() => history.push('/login')}>
                        {Locale.trans('login.back_login')}
                    </ForgottenButton>
                    <ActionButton
                        type="submit"
                        className={clsx(classes.button, {
                            [classes.button_loading]: loading,
                        })}
                        disabled={!canSubmit}
                        startIcon={isSubmitting ? (<CircularProgress />) : null}
                    >
                        {Locale.trans('login.send_reset')}
                    </ActionButton>
                </Grid>
            </Grid>
        </Form>
    );
}
export default ResetPasswordRequestForm;
