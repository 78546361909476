import * as React from 'react';
import {CircularProgress, Grid} from "@material-ui/core";
import clsx from "clsx";
import {Locale} from "ias-lib";
import ActionButton from "components/MaterialUi/ActionButton";
import {useForm} from "react-form";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import PasswordField from "shared/src/components/Fields/PasswordField/PasswordField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        item: {
            width: '100%',
            marginTop: '15px',
            marginBottom: '15px',
        },
        button: {},
        button_loading: {},
    })
);



type Props = {
    onSubmit: (mail: string) => void;
    loading: boolean;
}

const ForgottenPasswordRequestForm: React.FunctionComponent<Props> = ({onSubmit, loading}) => {
    const {
        Form,
        meta: {isSubmitting, canSubmit},
        getFieldValue,
    } = useForm({
        onSubmit: async (values: any, instance) => {
            await onSubmit(values.password as string);
        },
    });
    const classes = useStyles();
    return (
        <Form>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PasswordField className={clsx(classes.item)} />
                    <PasswordField className={clsx(classes.item)} field={"confirm"} sameAs={getFieldValue('password')} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ActionButton
                        type="submit"
                        className={clsx(classes.button, {
                            [classes.button_loading]: loading,
                        })}
                        disabled={!canSubmit}
                        startIcon={isSubmitting ? (<CircularProgress />) : null}
                    >
                        {Locale.trans('login.change_password')}
                    </ActionButton>
                </Grid>
            </Grid>
        </Form>
    );
}
export default ForgottenPasswordRequestForm;
