import React from 'react';

import clsx from "clsx";

import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import {Button, Grid} from "@material-ui/core";
import {Locale} from "ias-lib";
import ActionButton from "../../components/MaterialUi/ActionButton";
import {useForm} from 'react-form'
import {LoaderOverlay} from "../../components";
import {useHistory} from 'react-router-dom';
import Parameters from 'parameters';
import PasswordField from "shared/src/components/Fields/PasswordField/PasswordField";
import UsernameField from "shared/src/components/Fields/UsernameField/UsernameField";
import '../../components/Fields/LoginField.scss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        item: {
            width: '100%',
        },
        button: {
            fontFamily: 'Eesti, sans-serif',
            textTransform: 'lowercase',
            padding: '12px 24px',
            fontSize: '16px',
            borderRadius: '8px',
        },
        button_loading: {},
    })
);

const ForgottenButton = withStyles((theme: Theme) => ({
    root: {
        color: '#00B796',
        paddingLeft: 0,
        padding: '14px',
        fontSize: '14px',
        fontFamily: "Lato, sans-serif",
        fontWeight: 'normal',
        textTransform: 'none'
    },
}))(Button);


type Props = {
    onLogin: (login: string, password: string, arg: boolean) => void;
    loading: boolean;
}

export default function LoginForm({onLogin, loading}: Props) {
    const {
        Form,
        meta: {isSubmitting, canSubmit}
    } = useForm({
        onSubmit: async (values: any, instance: any) => {
            onLogin(values.username as string, values.password as string, false);
        },
        debugForm: Parameters.env === "dev",
    });
    const classes = useStyles();
    const history = useHistory();


    return (
        <Form>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <UsernameField className={clsx(classes.item)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PasswordField className={clsx(classes.item)} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ForgottenButton onClick={() => history.push('/reset-password-request')}>
                        {Locale.trans('login.username_forgotten')}
                    </ForgottenButton>
                    <ActionButton
                        type="submit"
                        className={clsx(classes.button, {
                            [classes.button_loading]: loading,
                        })}
                        disabled={!canSubmit}
                    >
                        {Locale.trans('login.login')}
                    </ActionButton>
                </Grid>
            </Grid>
            <LoaderOverlay loading={isSubmitting} />
        </Form>
    );
}



