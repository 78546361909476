import React, {useContext, useState} from 'react';
import './App.scss';
import AppLayout from "./views/Layout";
import { frFR, enUS } from '@material-ui/core/locale';


// Router
import {ProtectedRoute} from "components";
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import {AuthProvider} from "shared/src/contexts/AuthContext";
import Login from "./views/login/Login";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {Locale} from "ias-lib";
import {SnackbarProvider} from "notistack";
import Register from "./views/register/Main";
import PartnerRegister from "views/register/PartnerRegister";
import { ApolloProvider } from '@apollo/client';
import client from "shared/src/services/AppoloClient";
import fr from "./trans/messages.fr";
import en from "./trans/messages.en";
import ForgottenPasswordRequest from "./views/login/ForgottenPasswordRequest";
import ResetPasswordRequest from "./views/login/ResetPasswordRequest";
import LocaleContext, {LocaleProvider} from "shared/src/contexts/LocaleContext";
import {MenuManagementProvider} from "shared/src/contexts/MenuManagementContext";

// import Monicha from 'Monicha.otf';

// const raleway = {
//   fontFamily: 'TestFont',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   src: `
//     url(${Monicha}) format('otf')
//   `,
// };

const options = {
    palette: {
        primary: {
            /**
             * Preview: ![green 50](https://material-ui.com/static/colors-preview/green-50-24x24.svg)
             */
            main: '#00B796',
        },
    },
    // overrides: {
    //     MuiCssBaseline: {
    //       '@global': {
    //         '@font-face': [raleway],
    //       },
    //     },
    // },
};

const ThemeProviderWrapper = () => {
    const {locale} = useContext(LocaleContext);
    const [locales] = useState({
        fr: frFR,
        en: enUS,
    }) ;
    return (
        <ThemeProvider theme={createMuiTheme(options, locales[locale])}>
            <Router>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/register" component={Register}/>
                    <Route exact path="/reset-password-request" component={ResetPasswordRequest}/>
                    <Route exact path="/reset-password" component={ForgottenPasswordRequest}/>
                    <Route path="/partner/register" component={PartnerRegister}/>
                    <ProtectedRoute path="/" component={AppLayout}/>
                </Switch>
            </Router>
        </ThemeProvider>
    )
};
function App() {


    Locale.addTranslation({fr});
    Locale.addTranslation({en});


    return (
        <ApolloProvider client={client}>
            <SnackbarProvider
                maxSnack={3}
                preventDuplicate={true}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
            >
                <div className="App">
                    <AuthProvider>
                        <MenuManagementProvider>
                            <LocaleProvider>
                                <ThemeProviderWrapper/>
                            </LocaleProvider>
                        </MenuManagementProvider>
                    </AuthProvider>
                </div>
            </SnackbarProvider>
        </ApolloProvider>
    );
}

export default App;
