import * as React from 'react';
import {Route, RouteComponentProps} from "react-router";

type Props = {
    isGranted: boolean;
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    fallBackComponent?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    path?: string;
}

const IsGrantedRoute: React.FunctionComponent<Props> = ({isGranted, component, fallBackComponent, path}) => {
    if(!isGranted) {
        if (fallBackComponent) {
            return <Route component={fallBackComponent} path={path} />;
        }
        return null;
    }
    return (
        <Route component={component} path={path} />
    );
};
export default IsGrantedRoute;
