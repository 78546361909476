import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
} from "@material-ui/core";
import {useCallback, useEffect} from "react";
import {Locale} from "ias-lib";
import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import DateInput from "../DateInput/DateInput";

import "./FilterField.scss";
import AutocompleteField from "../AutocompleteField";
import {useForm} from "react-form";

const useStyles = makeStyles({
    fullWidth: {
        width: "100%",
    },
    flexEnd: {
        display: 'flex',
        justifyContent: "flex-end",
    },
    label: {
        display: "flex",
        alignItems: 'center',
        fontFamily: "Lato, sans-serif",
    },
    dialog: {
        overflowY: "auto",
        "& .MuiDialog-paper": {
            borderRadius: '8px',
            boxShadow: "none",
            overflowY: "initial",
        }
    },
    title: {
        "& .MuiTypography-h6": {
            fontFamily: "Eesti, sans-serif",
        },
    },
    inputTextfield: {
        fontFamily: "Lato, sans-serif",
    },
    pointer: {
        "& input": {
            cursor: "pointer",
        }
    }

});

type Choice = {
    value: string;
    name: string;
}

export type toFilter = {
    fieldName: string;
    val: string;
    choices?: Choice[];
    isDate?: boolean;
}

type Props = {
    baseData: any[]; //Need to be the props one not the state one, to have the base that doesn't change
    setObject: (arg: any[]) => void;
    toFilter: toFilter[];
    isModalOpen: boolean;
    handleModalClose: () => void;
    setIsFiltered: (arg: boolean) => void;
    filteredObject: any[];
    isFiltered: boolean;
    updateFilter: boolean;
    setUpdateFilter: (arg: boolean) => void;
    values: any[];
    setValues: (arg: any[]) => void;
};

export const FilterField: React.FunctionComponent<Props> = ({
     updateFilter,
     setUpdateFilter,
     isFiltered,
     filteredObject,
     setIsFiltered,
     baseData,
     handleModalClose,
     isModalOpen,
     setObject,
     toFilter,
     setValues,
     values,
 }) => {

    const classes = useStyles();



    // useEffect(() => {
    //     if (updateFilter) {
    //         setUpdateFilter(false);
    //         filter();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [updateFilter]);




    useEffect(() => {
        setValues(toFilter.map(() => "" ));
    }, [toFilter]);



    const handleOnChange = (value: any|Date, index: number) => {
        const tmp = [...values];
        tmp[index] = value;
        setValues(tmp);
    };

    const handleCancelClick = (index: number) => {
        const tmp = [...values];
        tmp[index] = "";
        setValues(tmp);
    };

    useEffect(() => {
        const filter = () => {
            const getValue = (key: string, object: any) => {
                let toReturn = "";
                if (key.includes('.')) {
                    const k = key.substr(0, key.indexOf("."));
                    const newKey = key.substr(key.indexOf(".") + 1);
                    toReturn = getValue(newKey, object[k]);
                } else {
                    if (!object) {
                        return "";
                    }
                    if (object[key]) {
                        return object[key];
                    }
                }

                return toReturn;
            };

            function hasFilter() {
                let hasFilter = false;
                values.forEach(val => {
                    if (val !== "") {
                        hasFilter = true;
                    }
                });
                return hasFilter;
            }

            if (!hasFilter()) {
                setObject(baseData);
                return;
            }

            const valuesToReturn = baseData.filter((object: any) => {
                let toReturn = true;
                values.forEach((value: any, index: number) => {
                    if (!toReturn) {
                        return;
                    }
                    if (value === "") {
                        //This is to allow empty filters;
                        return;
                    }
                    let val = getValue(toFilter[index].val, object);
                    if (toFilter[index].isDate) {
                        toReturn = toReturn && moment(val).format("DD/MM/YY") === moment(value).format('DD/MM/YY');
                    } else {
                        if (typeof value === "number") {
                            value = value.toString();
                        }
                        if (typeof val === "number") {
                            val = val.toString();
                        }
                        toReturn = toReturn && (val.toLowerCase().includes(value.toLowerCase()));
                    }
                });
                return toReturn;
            });
            setObject(valuesToReturn);
            setIsFiltered(true);
        }
        filter();
    }, [
        values,
        baseData,
        toFilter,
        setObject,
    ]);

    const {Form} = useForm({});


    return (
        <Dialog
            open={isModalOpen}
            onClose={() => {
                handleModalClose();
            }}
            fullWidth={true}
            maxWidth={'md'}
            className={classes.dialog}
        >
            <DialogTitle className={classes.title}>
                {Locale.trans("filter")}
            </DialogTitle>
            <DialogContent className={"dialog-content"}>
                <Form>
                    <Grid container spacing={3}>
                        {toFilter.map((element: toFilter, index: number) => {
                            return (
                                <React.Fragment key={element.fieldName}>
                                    <Grid item xs={2} className={classes.label}>
                                        <span>{Locale.trans(`filter.${toFilter[index].fieldName}`)}</span>
                                    </Grid>
                                    <Grid item xs={5}>
                                        {element.choices && (
                                            <AutocompleteField
                                                textFieldValue={values[index]}
                                                label={Locale.trans(`filter.${toFilter[index].fieldName}`)}
                                                options={element.choices}
                                                onChange={(e, newValue) => handleOnChange(newValue.value, index)}
                                                onClear={() => handleCancelClick(index)}
                                                field={toFilter[index].fieldName}
                                            />
                                        )}

                                        {element.isDate && (
                                            <DateInput
                                                value={values[index]}
                                                onChange={(e) => handleOnChange(e, index)}
                                            />
                                        )}
                                        {!element.choices && !element.isDate &&(
                                            <TextField
                                                variant={"outlined"}
                                                className={classes.fullWidth}
                                                value={values[index]}
                                                type={element.val.includes("date") ? "date" : "text"}
                                                onChange={(e) => handleOnChange(e.target.value, index)}
                                                InputProps={{
                                                    className: clsx(classes.inputTextfield, element.val.includes("date") && classes.pointer),
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    {!element.choices &&
                                        <Grid item xs={1}>
                                            <IconButton disableRipple onClick={() => handleCancelClick(index)}>
                                                <CloseIcon/>
                                            </IconButton>
                                        </Grid>
                                    }

                                    <Grid item xs={4}/>
                                </React.Fragment>
                            )
                        })}
                        <Grid item xs={10}/>
                        <Grid item xs={2} className={classes.flexEnd}>
                            <Button
                                onClick={handleModalClose}
                                variant={"contained"}
                                color={"primary"}
                                style={{color: "white"}}
                                className={classes.fullWidth}
                                disableRipple
                            >
                                {Locale.trans("filter")}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>

        </Dialog>
    );
};

export default FilterField