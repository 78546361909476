import * as React from 'react';
import {AppBar, Button, createStyles, Grid, Theme, Typography, withStyles} from "@material-ui/core";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../images/logo couleur.png";
import {Locale} from "ias-lib";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import image from "../../images/urn_aaid_sc_US_e462e56d-a471-434e-a46e-3bb3d3d19841.png";
import RegisterForm from "./forms/PartnerRegisterForm";
import {useSnackbar} from "notistack";

const REGISTER = gql`
  mutation createPartnerPublic($input: CreatePartnerInput!) {
    createPartnerPublic(input: $input) {
      id
      name
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            appBar: {
                background: 'white',
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                paddingRight: '50px',
            },
            title: {
                flexGrow: 1,
                textAlign: 'right',
                paddingRight: '10px',
            },
        }
    ));

const RegisteredButton = withStyles(() => ({
    root: {
        color: '#00B796',
        paddingLeft: 0,
        padding: '14px',
        textTransform: 'capitalize',
    },
}))(Button);

type Props = {}

const Main: React.FunctionComponent<Props> = () => {
    const classes = useStyles();
    const history = useHistory();
    const [submit] = useMutation(REGISTER, {
        onError: (e:any) => {
            console.log(e);
        },
        onCompleted: (e:any) => {
            console.log(e);
        }
    });
    const {enqueueSnackbar} = useSnackbar();


    return (
        <>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar)}
            >
                <Toolbar>
                    <img
                        style={{marginLeft: '0',}}
                        src={logo}
                        alt={Locale.trans("logo.fleetever")}
                        height={"50%"}
                    />
                    <Typography className={clsx(classes.title)} variant="h6" noWrap>
                        {Locale.trans('register.already_registered')}
                    </Typography>
                    <RegisteredButton onClick={() => history.push('/login')}>
                        {Locale.trans('register.go_to_connect')}
                    </RegisteredButton>
                </Toolbar>
            </AppBar>
            <Register goToNextPage={(values: any) => {
                submit({
                    variables: {
                        input: {
                            name: values.company,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            phone: values.phone,
                            address: values.addr,
                            compAddress: values.compAddress,
                            postalCode: values.postalCode,
                            city: values.city,
                            country: values.country,
                        }
                    },
                }).then(() => {
                    enqueueSnackbar(Locale.trans("partner.create.success"), {variant: "success"});
                    //history.push("/login");
                }).catch(() => {
                    enqueueSnackbar(Locale.trans("partner.create.error"), {variant: "error"});
                });
            }} />
        </>
    );
};


const useStyles1 = makeStyles((theme: Theme) =>
    createStyles({
            root: {
                height: '100vh'
            },
            form_part: {
                height: '100%',
                boxShadow: '-2px 0 2px 1px rgb(229, 233, 233)',
                zIndex: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '4%',
                textAlign: 'left',
            },
            form: {
                height: '50%',
                width: '50%',
                textAlign: 'left',
            },
            image_part: {
                maxHeight: '100vh',
                overflow: 'hidden',
                position: 'relative'
            },
            image: {
                height: '100vh',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1
            },
            image_overlay: {
                height: '100vh',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
            },
            image_overlay_content: {
                width: '75%',
                textAlign: 'left',
            },
            margin: {
                marginTop: '15px',
            },
            bold: {
                fontWeight: 'bolder',
            }
        }
    ));

type Props1 = {
    goToNextPage: (arg: object) => void;
}
const Register: React.FunctionComponent<Props1> = ({goToNextPage}) => {
    const classes = useStyles1();
    return (
        <Grid container className={clsx(classes.root)}>
            <Grid item className={clsx(classes.image_part)} xs={4}>
                <div className="logo">
                    <img src={image} alt="Logo" className={clsx(classes.image)}/>
                    <div className={clsx(classes.image_overlay)}>
                        <div className={clsx(classes.image_overlay_content)}>
                            <Typography variant="h4">
                                The new standard for Freight Forwarding
                            </Typography>
                            <Typography variant="body1" className={clsx(classes.margin)}>
                                "Working with Zencargo has directly increased our product margin. Their insightful
                                advice has helped us continuously optimise and execute cost savings across our network."
                            </Typography>
                            <Typography variant="body1" className={clsx(classes.margin, classes.bold)}>
                                Dom Moulding Logistics Manager, Swoon
                            </Typography>

                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item className={clsx(classes.form_part)} xs={8}>
                <Grid container>
                    <Grid item xs={12} className={clsx(classes.margin)}>
                        <RegisterForm onSubmit={goToNextPage} loading={false}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default Main;
