import React from 'react';
import {createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from "clsx";
import image from "../../images/urn_aaid_sc_US_e462e56d-a471-434e-a46e-3bb3d3d19841.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            image: {
                height: '100vh',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1
            },
            image_overlay: {
                height: '100vh',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
            },
            image_overlay_content: {
                width: '75%',
                textAlign: 'left',
            },
            margin: {
                marginTop: '15px',
            },
            italic: {
                fontFamily: 'Lato, sans-serif',
                fontStyle: 'italic',
                fontSize: '18px',
                color: 'rgba(255,255,255,0.8)'
            },
            title: {
                fontFamily: 'Eesti, sans-serif',
                fontSize: '30px',
            },
            signature: {
                fontSize: '16px',
                fontFamily: 'Lato, sans-serif',
                fontWeight: 'bolder',
            },
        }
    ));

const RegisterLeftPart = () => {

    const classes = useStyles();

    return (
        <div className="logo">
            <img src={image} alt="Logo" className={clsx(classes.image)}/>
            <div className={clsx(classes.image_overlay)}>
                <div className={clsx(classes.image_overlay_content)}>
                    <Typography variant="h4" className={classes.title}>
                        The new standard for Freight Forwarding
                    </Typography>
                    <Typography variant="body1" className={clsx(classes.margin, classes.italic)}>
                        "Working with Zencargo has directly increased our product margin. Their insightful
                        advice has helped us continuously optimise and execute cost savings across our network."
                    </Typography>
                    <Typography variant="body1" className={clsx(classes.margin, classes.signature)}>
                        Dom Mouldin
                        <br />Logistics Manager, Swoon
                    </Typography>
    
                </div>
            </div>
        </div>
    )
}

export default RegisterLeftPart;
