import * as React from 'react';

type Props = {}

const Main: React.FunctionComponent<Props> = () => {
    return (
        <>Support</>
    );
}
export default Main;

export {
    Main as Support,
};
