import {makeStyles} from "@material-ui/core/styles";
import {gql} from "@apollo/client";
import * as React from "react";
import {useSnackbar} from "notistack";
import {useMutation, useQuery} from "@apollo/client";
import {Locale} from "ias-lib";
import {useForm} from "react-form";
import {Button, Grid, IconButton, Link} from "@material-ui/core";
import clsx from "clsx";
import CompanyField from "components/CompoundFields/CompanyField";
import PhoneField from "components/Fields/PhoneField";
import AddressField from "components/CompoundFields/AddressField";
import {TextField} from "shared/src/components/Fields";
import {LoaderOverlay} from "components";
import {Skeleton} from "@material-ui/lab";
import {DropzoneAreaBase, DropzoneDialogBase, FileObject} from "material-ui-dropzone";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {getUrlFormatedToken} from "shared/src/contexts/AuthContext";
import {ButtonBase} from "shared/src/components/Layout/Buttons/Button/Button";
import './PartnerCompany.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import useConfirm from "shared/src/hooks/useConfirm";
import Confirm from "shared/src/components/Confirm";

const useStyles = makeStyles(() => ({
    title: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    core: {
        padding: '25px',
    },
    fileField: {
        padding: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    item: {
        width: '100%',
        marginTop: '15px',
        marginBottom: '0px',
    },
    item_input: {
        backgroundColor: 'white',
        borderRadius: '5px',
    },
    button: {
        float: 'right',
    },
}));

const GET_COMPANY = gql`
query partnerCompany($id: UUID!) {
    partnerCompany(id: $id) {
        id
        name
        eori
        numberId
        address
        compAddress
        country
        postalCode
        city
        phone
        description
        inttraId
        CGVFile {
            id
            title
            type
            fileName
            url
            createdAt
            companyId
        }
        logoFile{
            id
            title
            type
            fileName
            url
            createdAt
            companyId
        }
        certificationsFiles{
            id
            title
            type
            fileName
            url
            createdAt
            companyId
        }
    }
}
`;
const UPDATE_COMPANY = gql`
mutation editPartnerCompany($id: UUID!, $input: PartnerCompanyInput!) {
    editPartnerCompany(id: $id, input: $input) {
        id
        name
        eori
        numberId
        address
        compAddress
        country
        postalCode
        city
        phone
        description
        inttraId
    }
}
`;

const DELETE_PARTNER_DOCUMENT = gql`
mutation deletePartnerDocument($entityId: UUID!, $documentId: UUID!){
    deletePartnerDocument(entityId: $entityId, documentId: $documentId){
        id
        name
        eori
        numberId
        address
        compAddress
        country
        postalCode
        city
        phone
        description
        inttraId
        CGVFile {
            id
            title
            type
            fileName
            url
            createdAt
            companyId
        }
        logoFile{
            id
            title
            type
            fileName
            url
            createdAt
            companyId
        }
        certificationsFiles{
            id
            title
            type
            fileName
            url
            createdAt
            companyId
        }
    }
}
`;

const CREATE_DOCUMENT_PARTNER = gql`
    mutation createPartnerDocument ($title: String!, $type: String!, $file: FileUpload!, $entityId: UUID!, $documentType: String!) {
        createPartnerDocument (title: $title, type: $type, file: $file, entityId: $entityId, documentType: $documentType) {
            id
            CGVFile {
                id
                title
                type
                fileName
                url
            }
            logoFile {
                id
                title
                type
                fileName
                url
            }
            certificationsFiles {
                id
                title
                type
                fileName
                url
            }

        }
    }
`;

type PropsContent = {
    id: string
}

const PartnerCompany: React.FunctionComponent<PropsContent> = ({id}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();


    const {
        isOpen,
        close,
        open,
        title,
        content,
        idSaved,
    } = useConfirm(Locale.trans('company.deleteDocument.title'));


    const [cgvFileObject, setCgvFileObject] = useState<FileObject | null>(null);
    const [logoFileObject, setLogoFileObject] = useState<FileObject | null>(null);
    const [certificationsFilesObject, setCertificationsFilesObject] = useState<FileObject[]>([]);

    const [baseCgvFile, setBaseCgvFile] = useState<any>(null);
    const [baseLogoFile, setBaseLogoFile] = useState<any>(null);
    const [baseCertificationsFiles, setBaseCertificationsFiles] = useState<any>(null);

    const [deleteDocument] = useMutation(DELETE_PARTNER_DOCUMENT);

    const {data, loading, error} = useQuery(GET_COMPANY, {
        variables:{id},
        onError: () => {
            enqueueSnackbar(Locale.trans('error.loading'), {variant: "error"});
        },
        fetchPolicy: "cache-and-network"
    });
    const [submit, {loading: submitting}] = useMutation(UPDATE_COMPANY);
    const {
        Form,
        meta: {isSubmitting, canSubmit}
    } = useForm({
        onSubmit: async (values: any) => {
            const {name, eori, numberId, address, compAddress, country, postalCode, city, phone, description, inttraId} = values;
            const company = {
                name, eori, numberId, address, compAddress, country, postalCode, city, phone, description, inttraId
            };
            submit({
                variables: {
                    id,
                    input: company,
                },
            }).then(() => {
                sendFiles(id);
                enqueueSnackbar(Locale.trans('profile.updated.company'), {variant: "success"});
                setCertificationsFilesObject([]);
            }).catch(() => {
                enqueueSnackbar(Locale.trans('error.undefined'), {variant: "error"});
            })
        },
        defaultValues: data ? data.partnerCompany : undefined,
    });


    const [createPartnerDocument] = useMutation(CREATE_DOCUMENT_PARTNER);


    const sendFiles = (partnerId: string) => {
        const sendCGV = (cgvFileObject: FileObject) =>
            createPartnerDocument({
                variables: {
                    title: cgvFileObject.file.name,
                    type: cgvFileObject.file.type,
                    file: cgvFileObject.file,
                    entityId: partnerId,
                    documentType: "cgv"
                }
            }).then(() => {
                enqueueSnackbar(Locale.trans('company.createDocument.success'), {variant: "success"});
                setCgvFileObject(null);
                history.push('/profile/company');
            }).catch(() => {
                enqueueSnackbar(Locale.trans('company.createDocument.error'), {variant: "error"});
            });
        const sendLogo = (logoFileObject: FileObject) =>
            createPartnerDocument({
                variables: {
                    title: logoFileObject.file.name,
                    type: logoFileObject.file.type,
                    file: logoFileObject.file,
                    entityId: partnerId,
                    documentType: "logo"
                }
            }).then(() => {
                enqueueSnackbar(Locale.trans('company.createDocument.success'), {variant: "success"});
                setLogoFileObject(null);
                history.push('/profile/company');
            }).catch(() => {
                enqueueSnackbar(Locale.trans('company.createDocument.error'), {variant: "error"});
            });
        const sendCertifications = (certificationsFilesObject: FileObject[]) =>
            certificationsFilesObject.forEach((certificationsFile: FileObject) => {
                createPartnerDocument({
                    variables: {
                        title: certificationsFile.file.name,
                        type: certificationsFile.file.type,
                        file: certificationsFile.file,
                        entityId: partnerId,
                        documentType: "certifications"
                    }
                    }).then(() => {
                        enqueueSnackbar(Locale.trans('company.createDocument.success'), {variant: "success"});
                        setCertificationsFilesObject([]);
                        history.push('/profile/company');
                    }).catch(() => {
                        enqueueSnackbar(Locale.trans('company.createDocument.error'), {variant: "error"});
                    });
                })

        if(cgvFileObject && logoFileObject && certificationsFilesObject){
            sendCGV(cgvFileObject).then(()=> sendLogo(logoFileObject)).then(()=> sendCertifications(certificationsFilesObject));
        } else if(cgvFileObject && logoFileObject){
            sendCGV(cgvFileObject).then(()=> sendLogo(logoFileObject));
        } else if(cgvFileObject && certificationsFilesObject){
            sendCGV(cgvFileObject).then(()=> sendCertifications(certificationsFilesObject));
        }else if(logoFileObject && certificationsFilesObject){
            sendLogo(logoFileObject).then(()=> sendCertifications(certificationsFilesObject));
        }else if(logoFileObject){
            sendLogo(logoFileObject);
        }else if(cgvFileObject){
            sendCGV(cgvFileObject);
        }else if(certificationsFilesObject){
            sendCertifications(certificationsFilesObject);
        }

    };

    React.useEffect(() => {
        if (data?.partnerCompany) {
            setBaseCgvFile(data?.partnerCompany.CGVFile);
            setBaseLogoFile(data?.partnerCompany.logoFile);
            setBaseCertificationsFiles(data?.partnerCompany.certificationsFiles);
        }
    }, [data]);

    const [fileParent, setFileParent] = useState<string|null>(null);
    const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);

    const handleOpenFile = (parent: string) => {
        setFileParent(parent);
        setFileModalOpen(true);
    };

    const handleAddFile = (selectedFiles: FileObject[]) => {
        if (selectedFiles.length > 0) {
            if(fileParent === "cgv"){
                setCgvFileObject(selectedFiles[selectedFiles.length-1])
            }
            else if (fileParent === "logo") {
                setLogoFileObject(selectedFiles[selectedFiles.length-1])
            }
            else {
                setCertificationsFilesObject(selectedFiles)
            }
        }
    };

    const onDelete = () => {
        if(fileParent === "cgv"){
            setCgvFileObject(null);
        }
        else if (fileParent === "logo") {
            setLogoFileObject(null)
        }
        else {
            setCertificationsFilesObject([])
        }
    };

    const handleDelete = () => {
        deleteDocument({
            variables: {
                entityId: id,
                documentId: idSaved
            }
        }).then(() => {
            enqueueSnackbar(Locale.trans('company.deleteDocument.success'), {variant: "success"});
            close();
        }).catch(() => {
            enqueueSnackbar(Locale.trans('company.deleteDocument.error'), {variant: "error"});
            close();
        })
    }

    if (loading) return (
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Skeleton variant="rect" width={"100%"} height={350} animation={"wave"}/>
            </Grid>
        </Grid>
    );
    if (error) return null;
    return (
        <Form>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <span className="sub-title">
                                {Locale.trans('company.information')}
                            </span>
                        </Grid>
                        <Grid item xs={8}>
                            <CompanyField/>
                        </Grid>
                        <Grid item xs={6}>
                            <PhoneField className={clsx(classes.item)} inputStyle={clsx(classes.item_input)}
                                        field="phone" required/>
                        </Grid>
                        <Grid item xs={8}>
                            <AddressField hasCountry
                                          country={data && data.partnerCompany.country}
                                          streetField={"address"}
                                          compAddressField={"compAddress"}
                                          postalCodeField={"postalCode"}
                                          cityField={"city"} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                inputStyle={clsx(classes.item_input)}
                                className={clsx(classes.item)}
                                field="inttraId"
                                label={Locale.trans('company.inttraId')}
                                required
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <span className="sub-title">
                                {Locale.trans('company.presentation')}
                            </span>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                className={clsx(classes.item)}
                                inputStyle={clsx(classes.item_input)}
                                field="description"
                                multiline
                                rows={5}
                                label={Locale.trans("company.description")}
                            />
                        </Grid>
                        <Grid item xs={4} className="company-logo-border">
                            <div className="company-logo-title" style={{color:"grey"}}>
                                {Locale.trans("company.logo")}
                            </div>
                            <Button
                                className="company-logo-button"
                                style={{marginLeft: "25px"}}
                                variant="outlined"
                                component="span"
                                onClick={() => handleOpenFile("logo")}
                                disableRipple={true}
                                >
                                {logoFileObject
                                    ? (<span>{logoFileObject?.file.name}</span>)
                                    : baseLogoFile
                                        ?(<img
                                            src={`${baseLogoFile.url}?X-AUTH-TOKEN=${getUrlFormatedToken()}`}
                                            alt={baseLogoFile.fileName}
                                            className="company-logo-thumbnail" />)
                                        : (<span>{Locale.trans("file.noFileSelected")}</span>)
                                }
                            </Button>
                        </Grid>
                        <Grid item xs={8}>
                            <div className="company-cgv-title" style={{color:"grey"}}>
                                {Locale.trans("company.cgv")}
                            </div>
                            <div>

                                <i className="material-icons" style={{verticalAlign: "text-bottom", marginRight:"5px"}}>picture_as_pdf</i>
                                {cgvFileObject
                                    ? (
                                        <span>{cgvFileObject.file.name}</span>
                                    )
                                    : baseCgvFile
                                        ?(
                                            <Link href={`${baseCgvFile.url}?X-AUTH-TOKEN=${getUrlFormatedToken()}`}>{baseCgvFile.fileName}</Link>
                                        )

                                        : (

                                            <span>{Locale.trans("file.noFileSelected")}</span>
                                        )
                                }
                                <Button style={{marginLeft: "25px"}} variant="outlined" component="span" onClick={() => handleOpenFile("cgv")} disableRipple={true} >
                                    {Locale.trans("file.select")}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <span className="sub-title">
                                {Locale.trans('company.certifications')}
                            </span>
                        </Grid>
                        <Grid item xs={8}>
                            <div className="company-certifications-dropzone" onClick={() => setFileParent("certifications")}>
                                <DropzoneAreaBase
                                    filesLimit={10}
                                    dropzoneText={Locale.trans('uploadDialog.dropzoneOneFile')}
                                    fileObjects={certificationsFilesObject}
                                    showFileNames
                                    onAdd={handleAddFile}
                                />
                            </div>
                            <div className={"certification-files"}>
                                {baseCertificationsFiles?.map((file: any) => {
                                    return(
                                        <div className={"file-link"}>
                                            <i className="material-icons">picture_as_pdf</i>
                                            <Link href={`${file.url}?X-AUTH-TOKEN=${getUrlFormatedToken()}`}>{file.fileName}</Link>
                                            <IconButton className={"delete-icon"} onClick={() => open(file.id, Locale.trans('company.deleteDocument.content', {"FILE": file.fileName}))}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </div>
                                    );
                                })}
                            </div>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                            <ButtonBase
                                variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                                disabled={!canSubmit}
                                label={Locale.trans('register.next')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DropzoneDialogBase
                dropzoneClass="message-dialog"
                dialogTitle={Locale.trans('uploadDialog.title')}
                dropzoneText={Locale.trans('uploadDialog.dropzoneOneFile')}
                cancelButtonText={Locale.trans('uploadDialog.cancel')}
                submitButtonText={Locale.trans('uploadDialog.submit')}
                getFileAddedMessage={(fileName) => (`${fileName} ${Locale.trans("uploadDialog.fileAdded")}`)}
                getFileRemovedMessage={(fileName) => (`${fileName} ${Locale.trans("uploadDialog.fileDeleted")}`)}
                acceptedFiles={fileParent === "cgv" ? [".pdf"] : [".jpg", ".png"]}
                filesLimit={1}
                maxWidth={"sm"}
                onAdd={handleAddFile}
                onDelete={onDelete}
                fileObjects={fileParent === "cgv" ? cgvFileObject ? [cgvFileObject] : [] : logoFileObject ? [logoFileObject] : []}
                onClose={() => {
                    setFileModalOpen(false);
                    onDelete();
                }}
                onSave={() => {
                    setFileModalOpen(false);
                }}
                open={fileModalOpen}
                showPreviews={false}
                showFileNames={true}
                showPreviewsInDropzone={true}
            />
            <LoaderOverlay loading={isSubmitting || submitting} />
            <Confirm
                open={isOpen}
                onClose={close}
                title={title}
                content={content}
                onAccept={handleDelete}
                onRefuse={close}
            />
        </Form>
    );
};

export default PartnerCompany;